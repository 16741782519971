<script>
exports.AssetsService = (api) => {
  const basePath = '/assets'
  const adminPath = `/admin${basePath}`

  const queryBuilder = (query = {}) => {
    let queryBuilder = []
    for (const key in query) {
      if (query[key]) {
        queryBuilder.push(`${key}=${query[key]}`)
      }
    }
    queryBuilder = queryBuilder.length ? '?' + queryBuilder.join('&') : ''
    return queryBuilder
  }

  const handleError = (error) => {
    throw new Error(error)
  }

  const writeNote = async (data) => {
    try {
      return await api.post(`${adminPath}/notes/new`, data)
    } catch (error) {
      handleError(error)
    }
  }

  const updateNote = async (uid, data) => {
    try {
      return await api.put(`${adminPath}/notes/uid/${uid}`, data)
    } catch (error) {
      handleError(error)
    }
  }

  const getNotes = async ({ assetUid, pager, sort }) => {
    try {
      return await api.get(
        `${adminPath}/notes/uid/${assetUid}${queryBuilder({
          ...pager,
          sort
        })}`
      )
    } catch (error) {
      handleError(error)
    }
  }

  return {
    Admin: {
      writeNote,
      updateNote,
      getNotes
    }
  }
}
</script>
