<template>
  <div class="containert-lg custom-font-size">
    <div class="row">
      <div class="col-lg"></div>
      <div class="col-lg-11">
        <div class="row">
          <div
            class="col-sm-5 text-left"
            style="vertical-align: top !important"
          >
            <SingleTile
              v-for="tile in tiles.filter((tile) => tile.isSystemProject)"
              :key="tile.uid"
              :tile="tile"
            />
          </div>
          <div
            class="col-sm-7 text-left"
            style="vertical-align: top !important"
          >
            <SingleTile
              v-for="tile in SortList(
                tiles.filter((tile) => !tile.isSystemProject)
              )"
              :key="tile.uid"
              :tile="tile"
            />
            <SingleTile
              v-if="showAddNewProject"
              :ico="'b-icon-plus'"
              :fn="toggleNewProjectModal"
            />
            <SingleTile
              v-if="showRefreshBtn"
              :ico="'b-icon-arrow-repeat'"
              :fn="getActiveProjects"
            />
          </div>
        </div>
      </div>
      <div class="col-lg"></div>
    </div>
    <b-modal
      v-model="newProjectModal.show"
      centered
      size="sm"
      button-size="sm"
      :title="newProjectModal.data.title"
      :header-bg-variant="newProjectModal.headerBgVariant"
      :header-text-variant="newProjectModal.headerTextVariant"
      :body-bg-variant="newProjectModal.bodyBgVariant"
      :body-text-variant="newProjectModal.bodyTextVariant"
      :footer-bg-variant="newProjectModal.footerBgVariant"
      :footer-text-variant="newProjectModal.footerTextVariant"
    >
      <b-container fluid>
        <b-row class="mb-1">
          <b-col
            class="modal-form-input"
            style="font-size: 8pt !important"
            sm="5"
            >Code:</b-col
          >
          <b-col sm="7">
            <b-form-input
              size="sm"
              v-model="newProjectModal.data.projectCode"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            class="modal-form-input"
            style="font-size: 8pt !important"
            sm="5"
            >Name:</b-col
          >
          <b-col sm="7">
            <b-form-input
              size="sm"
              v-model="newProjectModal.data.projectName"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            class="modal-form-input"
            style="font-size: 8pt !important"
            sm="5"
            >Colour:</b-col
          >
          <b-col sm="7">
            <b-button
              v-b-modal.modal-color-pallet
              size="sm"
              style="width: 100%; hight: 30px"
              :style="
                'background-color: ' +
                newProjectModal.data.selectedColor.hex +
                ' !important'
              "
              ><p>&nbsp;</p></b-button
            >
            <!-- <b-form-input size="sm" type="color"></b-form-input> -->
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="dark"
            size="sm"
            class="float-right"
            @click="addNewProject"
          >
            Submit
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      id="modal-color-pallet"
      hide-backdrop
      hide-footer
      no-fade
      :header-bg-variant="newProjectModal.colorPalletModal.headerBgVariant"
      :header-text-variant="newProjectModal.colorPalletModal.headerTextVariant"
      :body-bg-variant="newProjectModal.colorPalletModal.bodyBgVariant"
      :body-text-variant="newProjectModal.colorPalletModal.bodyTextVariant"
      :footer-bg-variant="newProjectModal.colorPalletModal.footerBgVariant"
      :footer-text-variant="newProjectModal.colorPalletModal.footerTextVariant"
      title="Colour Pallet"
    >
      <p class="my-4">
        <color-picker v-model="newProjectModal.data.selectedColor" />
      </p>
    </b-modal>
  </div>
</template>

<script>
import Services from '../../../services/main.service.vue'
import SingleTile from './SingleTile.vue'

export default {
  name: 'ProjectTiles',
  components: {
    SingleTile
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    HasAdminPerm() {
      return this.Store.userAuth.hasAdminPrivileges
    },
    StaffingActiveProjects() {
      return this.Store.staffingActiveProjects
    }
  },
  data() {
    return {
      showAddNewProject: true,
      showRefreshBtn: true,
      tiles: [],
      newProjectModal: {
        show: false,
        headerBgVariant: 'dark',
        headerTextVariant: 'light',
        bodyBgVariant: 'light',
        bodyTextVariant: 'dark',
        footerBgVariant: 'light',
        footerTextVariant: 'dark',
        colorPalletModal: {
          headerBgVariant: 'dark',
          headerTextVariant: 'light',
          bodyBgVariant: 'dark',
          bodyTextVariant: 'light',
          footerBgVariant: 'dark',
          footerTextVariant: 'light'
        },
        data: {
          title: 'Add New',
          projectCode: '',
          projectName: '',
          selectedColor: '#000'
        }
      }
    }
  },
  mounted() {
    this.tabMounted()
  },
  methods: {
    async tabMounted() {
      await this.getActiveProjects(false)
    },
    async getActiveProjects(force = true) {
      if (this.StaffingActiveProjects.length && !force) {
        this.tiles = this.StaffingActiveProjects
      } else {
        this.tiles = []
        let systemProjects = []
        const Projects = await Services.Projects.getList({
          active: true
        })
        const costTrackerInfo = await this.getCostTrackerInfo()
        Projects.data.content.forEach((project) => {
          if (!['Conference'].includes(project.title)) {
            if (project.meta?.system) {
              systemProjects.push({
                uid: project.uid,
                name: project.title,
                color: project.meta?.color ?? '#000',
                isSystemProject: true
              })
            } else {
              const tileObj = {
                uid: project.uid,
                name: project.title,
                color: project.meta?.color ?? '#000',
                isSystemProject: false,
                costLevelValue: costTrackerInfo[project.uid]?.val ?? 0
              }
              tileObj.costLevelColor =
                costTrackerInfo[project.uid]?.color ?? tileObj.color
              this.tiles.push(tileObj)
            }
          }
        })
        // SORT

        // console.log({ tiles: systemProjects })
        let list = [
          'BD',
          'BD w/Len',
          'BD w/Marc',
          'BD w/Michelle',
          'BD w/Floris',
          'BD w/Kumar',
          'BD w/KK',
          'BD w/Jack',
          'BD w/Fabio',
          'BD w/Blake',
          'BD w/Nicolas',
          'BD Strategy',
          'Sector Decks',
          'Career Event',
          'Training'
        ]
        systemProjects = systemProjects.sort((a, b) => {
          const aIndex = list.indexOf(a.name)
          const bIndex = list.indexOf(b.name)
          if (aIndex > bIndex) {
            return 1
          } else if (aIndex < bIndex) {
            return -1
          } else {
            return 0
          }
        })
        this.tiles = this.tiles
          .concat(systemProjects)
          .sort((a, b) => a.isSystemProject - b.isSystemProject)
        this.$store.commit('setStaffingActiveProjects', this.tiles)
      }

      this.showAddNewProject =
        this.showAddNewProject &&
        this.tiles.filter((project) => !project.isSystemProject).length < 20 &&
        this.HasAdminPerm
      // this.tiles = [
      //   {
      //     color: '#FF0000',
      //     uid: 'ANNUAL',
      //     isSystemProject: true,
      //     name: 'Annual Leave'
      //   },
      //   {
      //     color: '#660066',
      //     uid: 'PERSONAL',
      //     isSystemProject: true,
      //     name: 'Personal Leave'
      //   }
      // ].concat(this.tiles.sort((a, b) => a.isSystemProject - b.isSystemProject))
    },
    async addNewProject() {
      try {
        const { projectCode, projectName, selectedColor } =
          this.newProjectModal.data

        const { status, data } = await Services.Projects.Admin.create({
          code: projectCode,
          title: projectName,
          active: true,
          meta: {
            color: selectedColor.hex
          }
        })

        if (status == 200) {
          this.tiles.push({
            uid: data.content.uid,
            name: data.content.title,
            color: data.content.meta.color,
            isSystemProject: false
          })
        }

        this.toggleNewProjectModal()

        this.newProjectModal.data.projectCode = ''
        this.newProjectModal.data.projectName = ''
        this.newProjectModal.data.selectedColor = '#000'
      } catch (Exception) {
        console.log({ Excpeption })
        this.toggleNewProjectModal()

        this.newProjectModal.data.projectCode = ''
        this.newProjectModal.data.projectName = ''
        this.newProjectModal.data.selectedColor = '#000'
      }
    },
    async getCostTrackerInfo() {
      try {
        const { status, data } =
          await Services.Projects.Admin.getProjectsTracker({})
        if (status === 200) {
          return data.content.list.reduce((acc, item) => {
            const { val, color } = item.costUtilToDate
            acc[item.uid] = ['#ffffff', '#fff', 'white'].includes(
              color.toLowerCase()
            )
              ? null
              : {
                  val,
                  color
                }
            return acc
          }, {})
        } else {
          return {}
        }
      } catch (error) {
        return {}
      }
    },
    toggleNewProjectModal() {
      this.newProjectModal.show = !this.newProjectModal.show
    },
    SortList(list = []) {
      return list.sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  watch: {
    'newProjectModal.data.selectedColor': function () {
      this.$bvModal.hide('modal-color-pallet')
    }
  }
}
</script>

<style scoped>
.modal-title {
  font-size: 11pt !important;
}
.new-project {
  max-width: calc(100% / 10);
  width: 100px;
  background-color: #eee !important;
  color: #333 !important;
  margin: 0 3px;
  padding: 6px;
  text-align: center;
  font-size: 9pt;
  font-weight: 600;
  border-radius: 2px;
  display: inline-block;
}
.new-project:hover {
  cursor: pointer;
  color: #fff !important;
  background-color: #333 !important;
}
.new-project:active {
  cursor: pointer;
  color: #999 !important;
  background-color: #222 !important;
}
</style>
