<template>
  <b-container fluid class="bg-white">
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="HasAdminPerm">
      <div class="col-lg"></div>
      <div class="col-lg-11">
        <div class="row">
          <div class="col-lg-9 lizard-col-padding"></div>
          <div class="col-lg-3 lizard-col-padding text-right">
            <ul class="lizard-btn-list">
              <li>
                <div
                  v-b-tooltip.hover.bottom="{ variant: 'info' }"
                  title="Add New Asset"
                  class="lizard-monday-btn new-asset-btn"
                  :class="{ 'lizard-monday-sync': loadingAssetBoard }"
                  @click="fetchFromMonday"
                >
                  add new asset
                </div>
              </li>
              <li>
                <div
                  v-b-tooltip.hover.bottom="{ variant: 'info' }"
                  title="Sync with Monday.com"
                  class="lizard-monday-btn"
                  :class="{ 'lizard-monday-sync': loadingAssetBoard }"
                  @click="fetchFromMonday"
                >
                  <img
                    src="../../../assets/monday-1.jpg"
                    class="lizard-monday-button"
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg"></div>
    </div>
    <div class="row" v-if="HasAdminPerm">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <b-card bg-variant="light">
          <div class="row">
            <div class="col-lg-2 text-left lizard-col-padding">
              <span style="font-weight: bold; color: #999"
                ><b-icon-search font-scale="0.8"></b-icon-search>&nbsp;<span
                  style="font-variant: small-caps"
                  >asset</span
                >&nbsp;<span style="font-size: 8pt">(Search)</span>
              </span>
            </div>
            <div class="col-lg-2 text-left lizard-col-padding">
              <span style="font-weight: bold; color: #999"
                ><b-icon-list-ul font-scale="0.8"></b-icon-list-ul>&nbsp;<span
                  style="font-variant: small-caps"
                  >subsector</span
                >&nbsp;<span style="font-size: 8pt">(List)</span>
              </span>
            </div>
            <div class="col-lg-2 text-left lizard-col-padding">
              <span style="font-weight: bold; color: #999"
                ><b-icon-list-ul font-scale="0.8"></b-icon-list-ul>&nbsp;<span
                  style="font-variant: small-caps"
                  >status</span
                >&nbsp;<span style="font-size: 8pt">(List)</span>
              </span>
            </div>
            <div class="col-lg-2 text-left lizard-col-padding">
              <span style="font-weight: bold; color: #999"
                ><b-icon-list-ul font-scale="0.8"></b-icon-list-ul>&nbsp;<span
                  style="font-variant: small-caps"
                  >est. ebitda</span
                >&nbsp;<span style="font-size: 8pt">(Select)</span>
              </span>
            </div>
            <div class="col-lg-2 text-left lizard-col-padding">
              <span style="font-weight: bold; color: #999"
                ><b-icon-search font-scale="0.8"></b-icon-search>&nbsp;<span
                  style="font-variant: small-caps"
                  >ibank</span
                >&nbsp;<span style="font-size: 8pt">(Search)</span>
              </span>
            </div>
            <div class="col-lg-2 text-left lizard-col-padding">
              <span style="font-weight: bold; color: #999"
                ><b-icon-list-ul font-scale="0.8"></b-icon-list-ul>&nbsp;<span
                  style="font-variant: small-caps"
                  >hq country</span
                >&nbsp;<span style="font-size: 8pt">(Search)</span>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-2 text-left lizard-col-padding">
              <b-form-input
                size="sm"
                placeholder="Type and select..."
                v-model="assetFilter"
                @keyup.enter="selectFilterTag('asset')"
              ></b-form-input>
            </div>
            <div class="col-lg-2 text-left lizard-col-padding">
              <div
                class="d-flex justify-content-center mb-3"
                v-if="!metas.hqcountries"
              >
                <b-button size="sm" variant="light" disabled>
                  <b-spinner small type="grow" label="Loading..."></b-spinner>
                  Loading...
                </b-button>
              </div>
              <treeselect
                v-else
                placeholder="Type and select..."
                v-model="subsectorTreeSettings.val"
                :multiple="subsectorTreeSettings.multiple"
                :clearable="subsectorTreeSettings.clearable"
                :searchable="subsectorTreeSettings.searchable"
                :open-on-click="subsectorTreeSettings.openOnClick"
                :close-on-select="subsectorTreeSettings.closeOnSelect"
                :limit="subsectorTreeSettings.limit"
                :options="subsectorTreeSettings.options"
              />
              <!-- <b-form-select
                v-else
                v-model="filters.subsector.val"
                :options="metas.subsectors"
                placeholder="Subsector..."
                size="sm"
                @change="selectFilterTag('subsector')"
              ></b-form-select> -->
              <!-- <b-form-input
                size="sm"
                placeholder="Type and select..."
                v-model="subsectorFilter"
                @keyup.enter="selectFilterTag('subsector')"
              ></b-form-input> -->
            </div>
            <div class="col-lg-2 text-left lizard-col-padding">
              <div
                class="d-flex justify-content-center mb-3"
                v-if="!metas.assetsstatus"
              >
                <b-button size="sm" variant="light" disabled>
                  <b-spinner small type="grow" label="Loading..."></b-spinner>
                  Loading...
                </b-button>
              </div>
              <b-form-select
                v-else
                v-model="filters.status.val"
                :options="metas.assetsstatus"
                placeholder="Status..."
                size="sm"
                @change="selectFilterTag('status')"
              ></b-form-select>
              <!-- <b-form-input
                size="sm"
                placeholder="Type and select..."
                v-model="statusFilter"
                @keyup.enter="selectFilterTag('status')"
              ></b-form-input> -->
            </div>
            <div class="col-lg-2 text-left lizard-col-padding">
              <div
                class="d-flex justify-content-center mb-3"
                v-if="!ebitdaList.length"
              >
                <b-button size="sm" variant="light" disabled>
                  <b-spinner small type="grow" label="Loading..."></b-spinner>
                  Loading...
                </b-button>
              </div>
              <b-form-checkbox-group
                v-else
                class="lizard-ebitda-groups"
                v-model="ebitdaFilter"
                :options="ebitdaList"
                button-variant="outline-secondary"
                name="allowance-years"
                buttons
              ></b-form-checkbox-group>
            </div>
            <div class="col-lg-2 text-left lizard-col-padding">
              <b-form-input
                size="sm"
                placeholder="Type and select..."
                v-model="ibankFilter"
                @keyup.enter="selectFilterTag('ibank')"
              ></b-form-input>
            </div>
            <div class="col-lg-2 text-left lizard-col-padding">
              <!-- <div
                class="d-flex justify-content-center mb-3"
                v-if="!metas.hqcountries"
              >
                <b-button size="sm" variant="light" disabled>
                  <b-spinner small type="grow" label="Loading..."></b-spinner>
                  Loading...
                </b-button>
              </div> -->
              <!-- <b-form-select
                v-else
                v-model="filters.hqcountry.val"
                :options="metas.hqcountries"
                placeholder="HQ Country..."
                size="sm"
                @change="selectFilterTag('hqcountry')"
              ></b-form-select> -->
              <b-form-input
                size="sm"
                placeholder="Type and select..."
                v-model="hqcountryFilter"
                @keyup.enter="selectFilterTag('hqcountry')"
              ></b-form-input>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-2 text-left lizard-col-padding">
              <ul class="filterTags">
                <li
                  v-if="Filters.asset.length"
                  v-for="(item, idx) in Filters.asset"
                  :key="idx"
                >
                  <b-badge
                    @click="removeFilterTag('asset', item)"
                    variant="info"
                    class="lizard-setting-badge"
                    title="Click to remove this filter"
                    v-b-tooltip.hover.bottom="{ variant: 'secondary' }"
                    ><b-icon icon="x-circle-fill"></b-icon>
                    {{ item }}
                  </b-badge>
                </li>
                <li
                  v-if="filters.asset.temp.length"
                  v-for="item in filters.asset.temp"
                  :key="item"
                >
                  <b-badge
                    @click="selectFilterTag('asset', item)"
                    variant="secondary"
                    class="lizard-setting-badge lizard-setting-badge-temp"
                    title="Click to add this filter"
                    v-b-tooltip.hover.bottom="{ variant: 'info' }"
                  >
                    {{ item }}
                  </b-badge>
                </li>
              </ul>
            </div>
            <div class="col-lg-2 text-left lizard-col-padding"></div>
            <!-- <div class="col-lg-2 text-left lizard-col-padding">
              <ul class="filterTags">
                <li
                  v-if="Filters.subsector.length"
                  v-for="(item, idx) in Filters.subsector"
                  :key="idx"
                >
                  <b-badge
                    @click="removeFilterTag('subsector', item)"
                    variant="info"
                    class="lizard-setting-badge"
                    title="Click to remove this filter"
                    v-b-tooltip.hover.bottom="{ variant: 'secondary' }"
                    ><b-icon icon="x-circle-fill"></b-icon>
                    {{ item }}
                  </b-badge>
                </li>
                <li
                  v-if="filters.subsector.temp.length"
                  v-for="item in filters.subsector.temp"
                  :key="item"
                >
                  <b-badge
                    @click="selectFilterTag('subsector', item)"
                    variant="secondary"
                    class="lizard-setting-badge lizard-setting-badge-temp"
                    title="Click to add this filter"
                    v-b-tooltip.hover.bottom="{ variant: 'info' }"
                  >
                    {{ item }}
                  </b-badge>
                </li>
              </ul>
            </div> -->
            <div class="col-lg-2 text-left lizard-col-padding"></div>
            <div class="col-lg-2 text-left lizard-col-padding"></div>
            <div class="col-lg-2 text-left lizard-col-padding">
              <ul class="filterTags">
                <li
                  v-if="filters.ibank.list.length"
                  v-for="(item, idx) in filters.ibank.list"
                  :key="idx"
                >
                  <b-badge
                    @click="removeFilterTag('ibank', item)"
                    variant="info"
                    class="lizard-setting-badge"
                    title="Click to remove this filter"
                    v-b-tooltip.hover.bottom="{ variant: 'secondary' }"
                    ><b-icon icon="x-circle-fill"></b-icon>
                    {{ item }}
                  </b-badge>
                </li>
                <li
                  v-if="filters.ibank.temp.length"
                  v-for="item in filters.ibank.temp"
                  :key="item"
                >
                  <b-badge
                    @click="selectFilterTag('ibank', item)"
                    variant="secondary"
                    class="lizard-setting-badge lizard-setting-badge-temp"
                    title="Click to add this filter"
                    v-b-tooltip.hover.bottom="{ variant: 'info' }"
                  >
                    {{ item }}
                  </b-badge>
                </li>
              </ul>
            </div>
            <div class="col-lg-2 text-left lizard-col-padding">
              <ul class="filterTags">
                <li
                  v-if="filters.hqcountry.list.length"
                  v-for="(item, idx) in filters.hqcountry.list"
                  :key="idx"
                >
                  <b-badge
                    @click="removeFilterTag('hqcountry', item)"
                    variant="info"
                    class="lizard-setting-badge"
                    title="Click to remove this filter"
                    v-b-tooltip.hover.bottom="{ variant: 'secondary' }"
                    ><b-icon icon="x-circle-fill"></b-icon>
                    {{ item }}
                  </b-badge>
                </li>
                <li
                  v-if="filters.hqcountry.temp.length"
                  v-for="item in filters.hqcountry.temp"
                  :key="item"
                >
                  <b-badge
                    @click="selectFilterTag('hqcountry', item)"
                    variant="secondary"
                    class="lizard-setting-badge lizard-setting-badge-temp"
                    title="Click to add this filter"
                    v-b-tooltip.hover.bottom="{ variant: 'info' }"
                  >
                    {{ item }}
                  </b-badge>
                </li>
              </ul>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <table-view
          :loading="loadingAssetBoard"
          :listItems="listItems"
          :enums="enums"
        ></table-view>
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </b-container>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import Services from '../../../services/main.service.vue'
import TableView from './new-table-view.vue'

export default {
  name: 'AssetBoard',
  components: {
    Treeselect,
    TableView
  },
  data() {
    return {
      loadingAssetBoard: false,
      loadingText: 'Loading...',
      loadingStatus: 'light',
      metas: {},
      enums: {},
      listItems: [],
      ebitdaList: [],
      filters: {
        asset: {
          val: '',
          temp: [],
          list: []
        },
        subsector: {
          val: '',
          temp: [],
          list: []
        },
        status: {
          val: '',
          temp: [],
          list: []
        },
        ebitda: {
          list: []
        },
        ibank: {
          val: '',
          temp: [],
          list: []
        },
        hqcountry: {
          val: '',
          temp: [],
          list: []
        }
      },
      filterKeys: [],
      filterEbitda: '',
      subsectorTreeSettings: {
        value: null,
        multiple: true,
        clearable: true,
        searchable: true,
        openOnClick: true,
        closeOnSelect: true,
        limit: 3,
        options: [
          // {
          //   id: 'a',
          //   label: 'a',
          //   // children: [
          //   //   {
          //   //     id: 'aa',
          //   //     label: 'aa'
          //   //   },
          //   //   {
          //   //     id: 'ab',
          //   //     label: 'ab'
          //   //   }
          //   // ]
          // }
        ]
      }
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    HasAdminPerm() {
      return this.$store.state.user.hasAdminPrivileges
    },
    Pager() {
      return this.Store.assetBoardPager
    },
    Filters() {
      return this.Store.assetBoardFilters
    },
    SortOrders() {
      return this.Store.assetBoardSort.join(',')
    },
    assetFilter: {
      get() {
        return this.filters.asset.val
      },
      async set(value) {
        if (this.loadingAssetBoard) return
        if (value.trim().length < 1) {
          this.filters.asset.temp = []
          return
        }
        let filter =
          this.listItems.find((item) => {
            const reg = new RegExp(value, 'ig')
            return reg.test(item.asset)
          })?.asset ?? null
        if (!filter) {
          filter = await this.searchAssets(value)
        }

        if (filter) {
          if (
            !this.filters.asset.temp.includes(filter) &&
            !this.filters.asset.list.includes(filter)
          ) {
            this.filters.asset.temp.unshift(filter)
          }
        } else {
          this.filters.asset.temp = []
        }
      }
    },
    subsectorFilter: {
      get() {
        return this.filters.subsector.val
      },
      set(value) {
        if (this.loadingAssetBoard) return
        if (value.trim().length < 2) {
          this.filters.subsector.temp = []
          return
        }
        const filter =
          this.metas.subsectors.find((item) => {
            const reg = new RegExp(`^${value}`, 'i')
            return reg.test(item.text)
          })?.value ?? null
        if (filter) {
          if (
            !this.filters.subsector.temp.includes(filter) &&
            !this.filters.subsector.list.includes(filter)
          ) {
            this.filters.subsector.temp.unshift(filter)
          }
        } else {
          this.filters.subsector.temp = []
        }
        // if (this.loadingAssetBoard) return
        // if (value.trim().length < 1) {
        //   this.filters.subsector.temp = []
        //   return
        // }
        // this.metas.subsectors.forEach((item) => {
        //   const reg = new RegExp(value, 'gi')
        //   const val = item.value
        //   if (
        //     reg.test(item.text) &&
        //     !this.filters.subsector.temp.includes(val) &&
        //     !this.filters.subsector.list.includes(val) &&
        //     val.trim().length
        //   ) {
        //     this.filters.subsector.temp.unshift(val)
        //   }
        // })
        // this.filters.subsector.temp = this.filters.subsector.temp
        //   .filter((item) => {
        //     const reg = new RegExp(value, 'gi')
        //     return reg.test(item)
        //   })
        //   .sort((a, b) => a.localeCompare(b))
      }
    },
    statusFilter: {
      get() {
        return this.filters.status.val
      },
      set(value) {
        if (this.loadingAssetBoard) return
        if (value.trim().length < 1) {
          this.filters.status.temp = []
          return
        }
        const filter =
          this.metas.assetsstatus.find((item) => {
            const reg = new RegExp(value, 'gi')
            return reg.test(item.text)
          })?.value ?? null
        if (filter) {
          if (
            !this.filters.status.temp.includes(filter) &&
            !this.filters.status.list.includes(filter)
          ) {
            this.filters.status.temp.unshift(filter)
          }
        } else {
          this.filters.status.temp = []
        }
      }
    },
    ebitdaFilter: {
      get() {
        return this.filters.ebitda.list
      },
      set(list) {
        if (this.loadingAssetBoard) return
        this.filters.ebitda.list = list
        this.commitToStore('setAssetBoardPager', { page: 1 })
        this.commitToStore('setAssetBoardFilters', {
          filter: { key: 'ebitda', val: this.filters.ebitda.list }
        })
      }
    },
    ibankFilter: {
      get() {
        return this.filters.ibank.val
      },
      async set(value) {
        if (this.loadingAssetBoard) return
        if (value.trim().length < 1) {
          this.filters.ibank.temp = []
          return
        }
        let filter =
          this.listItems.find((item) => {
            const reg = new RegExp(value, 'ig')
            return reg.test(item.ibank)
          })?.ibank ?? null
        if (!filter) {
          filter = await this.searchIBanks(value)
        }

        if (filter) {
          if (
            !this.filters.ibank.temp.includes(filter) &&
            !this.filters.ibank.list.includes(filter)
          ) {
            this.filters.ibank.temp.unshift(filter)
          }
        } else {
          this.filters.ibank.temp = []
        }
      }
    },
    hqcountryFilter: {
      get() {
        return this.filters.hqcountry.val
      },
      set(value) {
        if (this.loadingAssetBoard) return
        if (value.trim().length < 1) {
          this.filters.hqcountry.temp = []
          return
        }
        this.metas.hqcountries.forEach((item) => {
          const reg = new RegExp(value, 'gi')
          const val = item.value
          if (
            reg.test(item.text) &&
            !this.filters.hqcountry.temp.includes(val) &&
            !this.filters.hqcountry.list.includes(val) &&
            val.trim().length
          ) {
            this.filters.hqcountry.temp.unshift(val)
          }
        })
        this.filters.hqcountry.temp = this.filters.hqcountry.temp
          .filter((item) => {
            const reg = new RegExp(value, 'gi')
            return reg.test(item)
          })
          .sort((a, b) => a.localeCompare(b))
      }
    }
  },
  mounted() {
    this.tabMounted()
    // this.$root.$on('parent_tab_assetboard_selected', this.tabMounted)
    this.$root.$on('fetch_table_view_data', () => {
      this.fetchData({ monday: false })
    })
    this.$root.$on('add_filter', ({ key, value }) => {
      // this.selectFilterTag(key, value)
      const multipleSelectFields = [
        'asset',
        // 'subsector',
        'ebitda',
        'ibank',
        'hqcountry'
      ]
      if (!multipleSelectFields.includes(key)) {
        this.filters[key].val = value.toLowerCase()
      }
      this.commitToStore('setAssetBoardPager', { page: 1 })
      this.commitToStore('setAssetBoardFilters', {
        filter: { key, val: [value] }
      })
    })
  },
  methods: {
    tabMounted() {
      this.fetchMetas()
      this.fetchEnums()
      this.fetchData({ monday: false })
    },
    commitToStore(commitName, value) {
      this.$store.commit(commitName, value)
      this.listItems = []
      this.fetchData({ monday: false })
    },
    fetchFromMonday() {
      if (this.loadingAssetBoard) {
        return
      }
      this.$bvToast.toast('Syncing with Monday.com...', {
        title: 'Please wait...',
        variant: 'info',
        solid: true
      })
      this.fetchData({ monday: true, notification: true })
        .then(() => {
          // Reset Pager
          this.commitToStore('setAssetBoardPager', { page: 1 })
        })
        .catch(() => {
          this.$bvToast.toast('Error syncing with Monday.com', {
            title: 'Error',
            variant: 'danger',
            solid: true
          })
        })
    },
    async fetchData({ monday = false, notification = false }) {
      try {
        this.loadingAssetBoard = true
        this.loadingStatus = 'secondary'
        this.loadingText = 'Please wait...'
        const { status, data } = await Services.Projects.Admin.getAssetBoard({
          monday,
          pager: this.Pager,
          filters: this.Filters,
          sort: this.SortOrders
        })
        if (status === 200) {
          this.loadingAssetBoard = false
          if (!data.content.list.length) {
            this.$bvToast.toast('No data found based on filters', {
              title: 'Empty',
              variant: 'info',
              solid: true
            })
            return
            // return this.fetchFromMonday()
          }
          this.ebitdaList = data.content.ebitdaList.map(
            ({ label: text, value }) => {
              return {
                text,
                value
              }
            }
          )
          const tblData = this.dataMounted(data.content)
          this.listItems = tblData
          this.loadingStatus = ''
          this.loadingText = ''
          if (notification) {
            this.$bvToast.toast('Synced with Monday.com', {
              title: 'Done!',
              variant: 'success',
              solid: true
            })
          }
        } else {
          this.loadingStatus = 'danger'
          this.loadingText =
            'Error fetching Asset Board, Please Try again later.'
          this.$bvToast.toast('Error fetching Asset Board', {
            title: 'Error',
            variant: 'danger',
            solid: true
          })
          this.loadingAssetBoard = false
        }
      } catch (error) {
        this.loadingAssetBoard = false
        throw error
      }
    },
    async fetchMetas() {
      const { status, data } = await Services.Projects.Admin.getMetas()
      if (status === 200) {
        const {
          assetBoard: { subsectors, assetsstatus, hqcountries }
        } = data.content.metas
        this.metas = { subsectors, assetsstatus, hqcountries }
        // this.metas.subsectors.unshift({
        //   value: '',
        //   text: 'Select one...'
        // })
        this.subsectorTreeSettings.options = this.metas.subsectors.map(
          ({ value, text, subcategories = [] }) => {
            const opts = {
              id: value,
              label: text
            }
            if (subcategories.length) {
              opts.children = subcategories.map(({ value, text }) => {
                return {
                  id: value,
                  label: text
                }
              })
            }
            return opts
          }
        )
        this.metas.assetsstatus.unshift({
          value: '',
          text: 'Select one...'
        })
        this.metas.hqcountries.unshift({
          value: '',
          text: 'Select one...'
        })
        this.metas.hqcountries.push({
          value: 'other',
          text: 'Other'
        })
      }
    },
    async fetchEnums() {
      const { status, data } =
        await Services.Projects.Admin.getAssetBoardEnums()
      if (status === 200) {
        this.enums = data.content
      }
    },
    async searchAssets(q) {
      const { status, data } = await Services.Projects.Admin.searchAssetBoard(q)
      if (status === 200) {
        return data.content.list[0]?.asset ?? null
      }
      return null
    },
    async searchIBanks(q) {
      const { status, data } = await Services.Projects.Admin.searchAssetBoard(
        q,
        'ibank'
      )
      if (status === 200) {
        return data.content.list[0]?.ibank ?? null
      }
      return null
    },
    createCSSClass(className, rules) {
      // Create a new style element
      const style = document.createElement('style')
      // Construct the CSS rule as a string
      const cssRule = `.${className} { ${rules} }`
      // Check if the style sheet supports inserting rules directly
      if (style.styleSheet) {
        style.styleSheet.cssText = cssRule // For IE
      } else {
        style.appendChild(document.createTextNode(cssRule)) // For other browsers
      }
      // Append the style element to the document head
      document.head.appendChild(style)
    },
    dataMounted({ list = [], total = 0 }) {
      list = list.map((item) => {
        const labels = ['subsector', 'status', 'deck', 'priority']
        for (const key in labels) {
          this.createCSSClass(
            `table-lizard-assetboard-class-${item.uid}-${labels[key]}`,
            `background-color: ${item[labels[key]]?.color ?? '#c4c4c4'};`
          )
        }
        item._cellVariants = {
          subsector: `lizard-assetboard-class-${item.uid}-subsector`,
          status: `lizard-assetboard-class-${item.uid}-status`,
          deck: `lizard-assetboard-class-${item.uid}-deck`,
          priority: `lizard-assetboard-class-${item.uid}-priority`
        }
        // item._rowVariant = 'lizard-assetboard-rowVar'
        return item
      })
      let result = new Array(total).fill({})
      for (let i = 0; i < list.length; i++) {
        result[(this.Pager.page - 1) * this.Pager.perPage + i] = list[i]
      }
      return result
    },
    getLabelFromMeta(group, item) {
      return (
        this.metas[group]?.find(
          (sub) => sub.value.toLowerCase() === item.toLowerCase()
        )?.text ?? null
      )
    },
    selectFilterTag(key, value = this.filters[key].temp[0]) {
      const multipleSelectFields = [
        'asset',
        'subsector',
        'ebitda',
        'ibank',
        'hqcountry'
      ]
      if (multipleSelectFields.includes(key)) {
        if (!this.filters[key].list.includes(value) && value) {
          this.filters[key].list.push(value)
          this.commitToStore('setAssetBoardPager', { page: 1 })
          this.commitToStore('setAssetBoardFilters', {
            filter: { key, val: this.filters[key].list }
          })
        }
      } else {
        this.commitToStore('setAssetBoardPager', { page: 1 })
        this.commitToStore('setAssetBoardFilters', {
          filter: { key, val: [this.filters[key].val] }
        })
      }
      this.filters[key].temp = []
    },
    removeFilterTag(key, value) {
      this.filters[key].val = ''
      this.filters[key].temp = []
      this.filters[key].list = this.filters[key].list.filter(
        (item) => item !== value
      )
      const toRemove = {}
      for (const key in this.filters) {
        toRemove[key] = this.filters[key].list
      }
      this.commitToStore('setAssetBoardPager', { page: 1 })
      this.commitToStore('resetAssetBoardFilters', toRemove)
    }
  },
  watch: {
    'subsectorTreeSettings.val': {
      handler(val) {
        val = val.map((item) => {
          const categoryIndex = this.subsectorTreeSettings.options.findIndex(
            (opt) => opt.id === item
          )
          const isMainCategory = categoryIndex > -1
          if (isMainCategory) {
            return this.subsectorTreeSettings.options[
              categoryIndex
            ].children.map((sub) => sub.id)
          } else {
            return item
          }
        })
        const toRemove = this.filters.subsector.list.filter(
          (itm) => !val.includes(itm)
        )
        if (toRemove.length) {
          toRemove.forEach((item) => {
            this.removeFilterTag('subsector', item)
          })
        }
        val.forEach((item) => {
          this.selectFilterTag('subsector', item)
        })
      }
    },
    Pager: {
      handler() {
        this.fetchData({ monday: false })
      },
      deep: true
    }
  }
}
</script>

<style scoped>
@import '@riophae/vue-treeselect/dist/vue-treeselect.min.css';
.lizard-btn-list {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  text-align: right !important;
}
.lizard-btn-list li {
  display: inline-block;
  margin: 0 1px;
  text-align: right;
}
.lizard-btn-list li div {
  width: 100% !important;
}
.lizard-btn-list li div.new-asset-btn {
  padding: 0.437rem !important;
  font-weight: 700;
  color: #666 !important;
  font-size: 10pt;
  text-transform: uppercase;
}
.lizard-monday-btn {
  margin: 0 auto;
  padding: 0;
  width: 60%;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  line-height: normal;
  text-align: center;
  cursor: pointer;
  float: right;
}
.lizard-monday-btn:hover {
  border-color: #999;
}
.lizard-monday-btn:active {
  border-color: #666;
}
.lizard-monday-btn img {
  height: 30px !important;
}
</style>

<style>
*:focus {
  border: transparent !important;
  outline: none !important;
}
ul.filterTags {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul.filterTags li {
  margin: 0 2px;
  display: inline-block;
}
.lizard-update-input {
  font-size: 0.8rem !important;
  font-weight: bold !important;
  border: none !important;
  background-color: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
  max-width: 150px !important;
  text-align: center !important;
}
.lizard-update-select {
  font-size: 0.8rem !important;
  font-weight: bold !important;
  max-width: 150px !important;
}
.lizard-text-cell-2 {
  width: calc(100vw / 7) !important;
  min-width: calc(100vw / 7) !important;
  max-width: calc(100vw / 7) !important;
  margin: 0 !important;
  padding: 5px !important;
  overflow: auto;
}
.lizard-status-cell-finding-bidder {
  background-color: #e8697d;
}
.lizard-status-cell-hired {
  background-color: #55d392;
}
.lizard-status-cell-talk-to-owner {
  background-color: #fdbc64;
}
.lizard-status-cell-deck-done {
  background-color: #349ecd;
}
.lizard-status-cell-do-deck {
  background-color: #b57de3;
}
.lizard-status-cell-default {
  background-color: #797e93;
}
.lizard-status-cell {
  color: white;
  min-width: 130px !important;
}
.lizard-status-cell div:hover {
  cursor: pointer;
}
.lizard-deck-cell-no-need {
  background-color: #349ecd;
}
.lizard-deck-cell-medium {
  background-color: #fdbc64;
}
.lizard-deck-cell-high {
  background-color: #e8697d;
}
.lizard-deck-cell-done {
  background-color: #55d392;
}
.lizard-deck-cell-default {
  background-color: #797e93;
}
.lizard-deck-cell {
  color: white;
  min-width: 130px !important;
}
.lizard-deck-cell div:hover {
  cursor: pointer;
}
.lizard-setting-badge {
  margin: 0;
  min-width: 60px !important;
}
.lizard-setting-badge:hover {
  cursor: pointer;
  color: #eee !important;
  opacity: 0.6 !important;
}
.lizard-setting-badge:active {
  color: #ccc !important;
  opacity: 0.8 !important;
}
.lizard-setting-badge-temp {
  opacity: 0.4 !important;
}
.lizard-monday-button {
  max-width: 10vw !important;
  margin: 0;
  padding: 8px 6px;
  cursor: pointer;
}
.lizard-monday-button:active {
  max-width: 90% !important;
}
.lizard-monday-sync {
  opacity: 0.5 !important;
  border: #666;
  cursor: default;
}
.lizard-monday-sync img {
  opacity: 0.5 !important;
}
.lizard-ebitda-groups {
  width: 100% !important;
  line-height: 1 !important;
}
.lizard-ebitda-groups label {
  background-color: #eee !important;
  color: #ccc !important;
  height: 32px !important;
  min-height: 32px !important;
  line-height: 2 !important;
  vertical-align: middle !important;
  box-sizing: border-box;
  min-width: 25% !important;
  font-size: 75% !important;
  padding: auto 0 !important;
  border: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lizard-ebitda-groups label:hover {
  background-color: #ccc !important;
  color: #eee !important;
}
.lizard-ebitda-groups label.focus {
  box-shadow: 0 0 0 transparent !important;
}
.lizard-ebitda-groups label.active {
  /* background-color: #17a2b8 !important; */
  background-color: #66ccff !important;
  color: #fff !important;
}

.vue-treeselect__control {
  height: 31px !important;
  border-color: #ced4da !important;
  border-radius: 0.25rem !important;
}

.vue-treeselect__placeholder,
.vue-treeselect__single-value {
  line-height: 29px !important;
}

.vue-treeselect__placeholder {
  color: #495057 !important;
  font-weight: 400 !important;
  font-size: 0.875rem !important;
}
</style>
