<template>
  <b-container fluid class="bg-white">
    <div class="row">
      <div class="col-lg-12">
        <b-table
          fixed
          striped
          small
          borderless
          responsive
          caption-top
          sort-icon-left
          table-class="lizard-activity-list lizard-candesic-table"
          thead-class="label"
          tbody-class="value"
          :busy.sync="loading"
          :items="listItems"
          :fields="listFields"
        >
          <template #table-caption>
            <span v-if="loading">
              <b-spinner small></b-spinner>
              Loading...
            </span>
            <span style="color: #999 !important" v-else>
              Projects List
              <span v-if="HasAdminPerm"
                >(Only projects labeled as 'TRACKING' in the project list are
                displayed here)</span
              >
            </span>
          </template>
          <template #cell(code)="data">
            <code style="font-size: 12px; color: #666">{{
              data.item.code
            }}</code>
          </template>
          <template #cell(actions)="data">
            <ul
              class="actions"
              v-if="
                HasAdminPerm && data.item.type === 'project' && !data.item._new
              "
            >
              <li v-if="!data.item._edit">
                <b-link
                  ><el-button
                    icon="el-icon-edit"
                    size="mini"
                    @click="toggleEdit(data.item)"
                    style="
                      background-color: transparent;
                      border: 0;
                      font-size: 12pt;
                    "
                    v-b-tooltip.hover.bottom="{ variant: 'dark' }"
                    :title="'Click to modify ' + data.item.title"
                  ></el-button
                ></b-link>
              </li>
              <li v-else>
                <b-link
                  ><el-button
                    icon="el-icon-check"
                    style="
                      background-color: transparent;
                      border: 0;
                      font-size: 12pt;
                    "
                    size="mini"
                    v-b-tooltip.hover.bottom="{ variant: 'dark' }"
                    title="Save changes"
                    @click="updateProject(data.item)"
                  ></el-button
                ></b-link>
              </li>
            </ul>
          </template>
          <template #cell(title)="data">
            <span
              v-b-tooltip.hover.bottom="{ variant: 'dark' }"
              :title="data.item.code"
              >{{ data.item.title }}</span
            >
            <!-- <code>{{ data.item }}</code> -->
          </template>
          <template #cell(weeks)="data">
            <b-form-input
              type="number"
              min="1"
              step="1"
              size="sm"
              v-if="data.item._edit"
              v-model="data.item.weeks"
              style="text-align: center"
              @change="updateProject(data.item, 'weeks')"
            ></b-form-input>
            <span v-else>{{ data.item.weeks }}</span>
          </template>
          <template #cell(em1)="data">
            <b-form-input
              type="number"
              min="0"
              step="1"
              size="sm"
              v-if="data.item._edit"
              v-model="data.item.em1"
              style="text-align: center"
              @change="updateProject(data.item, 'em1')"
            ></b-form-input>
            <span v-else>{{ data.item.em1 }}</span>
          </template>
          <template #cell(em2)="data">
            <b-form-input
              type="number"
              min="0"
              step="1"
              size="sm"
              v-if="data.item._edit"
              v-model="data.item.em2"
              style="text-align: center"
              @change="updateProject(data.item, 'em2')"
            ></b-form-input>
            <span v-else>{{ data.item.em2 }}</span>
          </template>
          <template #cell(associate1)="data">
            <b-select
              v-if="data.item._edit"
              v-model="data.item.associate1"
              style="text-align: center"
              size="sm"
              :options="associatesList"
              @change="updateProject(data.item, 'associate1')"
            />
            <span v-else>{{ getFPTLabel(data.item.associate1) }}</span>
          </template>
          <template #cell(associate2)="data">
            <b-select
              v-if="data.item._edit"
              v-model="data.item.associate2"
              style="text-align: center"
              size="sm"
              :options="associatesList"
              @change="updateProject(data.item, 'associate2')"
            />
            <span v-else>{{ getFPTLabel(data.item.associate2) }}</span>
          </template>
          <template #cell(analysts)="data">
            <b-form-input
              type="number"
              min="0"
              step="1"
              size="sm"
              v-if="data.item._edit"
              v-model="data.item.analysts"
              style="text-align: center"
              @change="updateProject(data.item, 'analysts')"
            ></b-form-input>
            <span v-else>{{ data.item.analysts }}</span>
          </template>
          <template #cell(revisedFinishDate)="data">
            <el-date-picker
              type="date"
              style="min-width: fit-content; width: 100%"
              size="small"
              format="dd/MM/yyyy"
              value-format="dd/MM/yyyy"
              v-if="data.item._edit"
              v-model="data.item.revisedFinishDate"
              @change="updateProject(data.item, 'revisedFinishDate')"
              placeholder="Select Date"
            >
            </el-date-picker>
            <span v-else>{{ data.item.revisedFinishDate | prettyDate }}</span>
          </template>
          <template #cell(theoreticalCostUtilToDate)="data">
            <span v-if="data.item.theoreticalCostUtilToDate"
              >{{ data.item.theoreticalCostUtilToDate }}%</span
            >
          </template>
          <template #cell(costUtilToDate)="data">
            <span
              v-if="data.item.costUtilToDate.val"
              class="rangeHolder"
              :style="{
                backgroundColor: data.item.costUtilToDate.color
              }"
              >{{ data.item.costUtilToDate.val }}%</span
            >
          </template>
          <template #cell(theoreticalCostToRevisedDate)="data">
            <span
              v-if="data.item.theoreticalCostToRevisedDate.val"
              class="rangeHolder"
              :style="{
                backgroundColor: data.item.theoreticalCostToRevisedDate.color
              }"
              >{{ data.item.theoreticalCostToRevisedDate.val }}%</span
            >
          </template>
        </b-table>
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="Pager.total > Pager.perPage">
      <div class="col-lg">
        <b-pagination
          v-model="Pager.page"
          :total-rows="Pager.total"
          :per-page="Pager.perPage"
          aria-controls="activityTable"
          size="sm"
          pills
          align="center"
          @change="onPageChange"
        ></b-pagination>
      </div>
    </div>
  </b-container>
</template>

<script>
import { format as dateFormat, parseISO } from 'date-fns'
import Services from '../../../services/main.service.vue'

export default {
  name: 'ProjectTrackerList',
  props: [
    'loading',
    'metas',
    'listItems',
    'showFeeCheckbox',
    'showProjectFilter'
  ],
  data() {
    return {
      q: null,
      pager: {
        currentPage: 1,
        perPage: 15
      },
      filters: {
        projectType: {
          selected: 'PROJECT',
          opts: []
        }
      },
      metaData: {},
      associatesList: [
        { value: 0, text: '-' },
        { value: 5, text: 'FT' },
        { value: 2.5, text: 'PT' }
      ],
      listFields: [
        {
          key: 'title',
          label: 'Title',
          sortable: false,
          thClass:
            'lizard-projects-tbl-cols lizard-projects-tbl2-cols-code text-left',
          tdClass:
            'lizard-projects-tbl-cols lizard-projects-tbl2-cols-code text-left'
        },
        {
          key: 'weeks',
          label: 'Paid weeks',
          sortable: false,
          thClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-team',
          tdClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-team'
        },
        {
          key: 'team',
          label: 'Team (FTE)',
          sortable: false,
          thClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-team',
          tdClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-team'
        },
        {
          key: 'em1',
          label: 'EM 1 (Days per week)',
          sortable: false,
          thClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-team',
          tdClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-team'
        },
        {
          key: 'em2',
          label: 'EM 2 (Days per week)',
          sortable: false,
          thClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-team',
          tdClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-team'
        },
        {
          key: 'associate1',
          label: 'Assoc. 1',
          sortable: false,
          thClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-team',
          tdClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-team'
        },
        {
          key: 'associate2',
          label: 'Assoc. 2',
          sortable: false,
          thClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-team',
          tdClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-team'
        },
        {
          key: 'analysts',
          label: 'Analysts (ppl)',
          sortable: false,
          thClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-team',
          tdClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-team'
        },
        {
          key: 'startDate',
          label: 'Start date',
          sortable: false,
          thClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-dates',
          tdClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-dates'
        },
        {
          key: 'lopFinishDate',
          label: 'LoP finish date',
          sortable: false,
          thClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-dates',
          tdClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-dates'
        },
        {
          key: 'revisedFinishDate',
          label: 'Revised finish date',
          sortable: false,
          thClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-dates',
          tdClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-dates'
        },
        {
          key: 'theoreticalCostUtilToDate',
          label: 'Theoretical cost utilisation to date',
          sortable: false,
          thClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-utils',
          tdClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-utils'
        },
        {
          key: 'costUtilToDate',
          label: 'Cost utilisation to date',
          sortable: false,
          thClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-utils',
          tdClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-utils'
        },
        {
          key: 'theoreticalCostToRevisedDate',
          label: 'Theoretical cost to revised date',
          sortable: false,
          thClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-utils',
          tdClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-utils'
        }
      ],
      list: [],
      toEdit: null,
      edit: {},
      exportExcel: false,
      exportType: 'xls',
      exportName: 'data.xls',
      exportWorksheet: "Candesic's Project Tracker Report",
      exportFields: {
        No: 'no',
        Name: 'name',
        Email: 'email',
        'Hourly Rate': 'hourlyRate'
      },
      exportData: [],
      onEdit: {
        uid: null,
        field: null,
        value: {},
        editting: false
      },
      beforeUpdateClonedProject: null,
      totalActiveProjectsAllowence: 20,
      updated: false
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    Pager() {
      return this.Store.projectsTrackerPager
    },
    HasAdminPerm() {
      return this.$store.state.user.hasAdminPrivileges
    }
  },
  mounted() {
    if (this.HasAdminPerm) {
      this.listFields.unshift({
        key: 'actions',
        label: 'Modify',
        thClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-actions',
        tdClass: 'lizard-projects-tbl-cols lizard-projects-tbl2-cols-actions'
      })
    }
  },
  methods: {
    toggleEdit({ uid, type, _edit = true }) {
      if (type !== 'project') {
        return
      }
      const listIndex = this.listItems.findIndex((itm) => itm.uid === uid)
      this.listItems[listIndex]._edit = !_edit
      if (!this.listItems[listIndex]._edit && this.updated) {
        this.updated = false
        this.$root.$emit('tab_projects_tracker_refresh')
      }
    },
    async updateProject(project, field = null) {
      try {
        if (!project || !project.uid) return
        const toUpdate = {}
        const itemIndex = this.listItems.findIndex(
          (itm) => itm.uid === project.uid
        )
        let itemToUpdate = this.listItems[itemIndex]
        // console.log({ itemToUpdate })
        if (itemToUpdate._new) {
          return
        }
        let fieldsToUpdate = [
          'weeks',
          'revisedFinishDate',
          'em1',
          'em2',
          'associate1',
          'associate2',
          'analysts'
        ]
        if (field) {
          fieldsToUpdate = fieldsToUpdate.filter((fld) => fld === field)
        }
        fieldsToUpdate.forEach((field) => {
          switch (field) {
            case 'revisedFinishDate':
              toUpdate[field] = itemToUpdate[field]
              break
            default:
              toUpdate[field] = Number(
                parseFloat(itemToUpdate[field]).toFixed(1)
              )
          }
        })
        const upd = await this.saveChanges(itemToUpdate, toUpdate)
        if (upd) {
          const updObj = {
            _edit: false
          }
          if (Object.keys(toUpdate).includes('status')) {
            const nextMetaIndex = toUpdate.status === 'LIVE' ? 0 : 1
            this.listItems[itemIndex].status =
              this.metas.status[nextMetaIndex].key === 'LIVE'
          }
          itemToUpdate = { ...itemToUpdate, ...updObj }
          // this.$bvToast.toast('Project updated successfully', {
          //   title: 'Success',
          //   variant: 'success',
          //   solid: true
          // })
          this.updated = true
        }
        if (!field) {
          this.toggleEdit(project)
        }
      } catch (Exception) {
        throw Exception
      }
    },
    async saveChanges(itemToUpdate, data = {}) {
      try {
        if (typeof itemToUpdate.uid === 'undefined' || !itemToUpdate.uid) {
          return
        }
        const { status, error } = await Services.Projects.Admin.update(
          itemToUpdate.uid,
          data
        )
        if (status === 200 && !error) {
          return true
        } else {
          console.log({ error })
        }
      } catch (Exception) {
        console.log({ Exception: Exception.message })
        return false
      }
    },
    generateExcel() {
      const dt = this.$moment().format('YYYY-MM-DD')
      this.exportName = `projects-list-report-${dt}.${this.exportType}`
      this.exportData = this.listItems.map((itm, idx) => {
        itm.no = idx + 1
        return itm
      })
    },
    resetExportData() {
      this.exportName = `data.${this.exportType}`
      this.exportData = []
    },
    updateField(field, item) {
      let value = item[field]
      if (field === 'created') {
        value = this.$moment(value).format('YYYY-MM-DD')
      }
      if (field === 'color') {
        value = item.meta[field]
      }
      this.onEdit = {
        uid: item.uid,
        field,
        value,
        editting: true
      }
    },
    updateItem({ uid: itemUid }) {
      const listIndex = this.listItems.findIndex(({ uid }) => uid === itemUid)
      this.listItems[listIndex]._edit = !this.listItems[listIndex]._edit
      if (this.listItems[listIndex]._edit) {
        // EDIT
        this.beforeUpdateClonedProject = JSON.parse(
          JSON.stringify(this.listItems[listIndex])
        )
      } else {
        // SAVE
        this.$root.$emit('update_project_tracker', this.listItems[listIndex])
        this.beforeUpdateClonedProject = null
      }
    },
    getPartnerLabel(partner, short = true) {
      const partnerObj = this.metas.partners.find(
        (itm) => itm.value === partner
      )
      return short ? partnerObj?.value : partnerObj?.text
    },
    getSectorLabel(sector) {
      return this.metas.sectors.find((itm) => itm.value === sector)?.text
    },
    getFPTLabel(fpt) {
      return this.associatesList.find((itm) => itm.value === fpt)?.text ?? 'N/A'
    },
    onPageChange(page) {
      this.$store.commit('updProjectsTrackerPager', { page })
      this.$root.$emit('tab_projects_tracker_refresh')
    }
  },
  filters: {
    prettyDate(value) {
      if (!value) {
        return
      }
      return dateFormat(
        parseISO(value.split('/').reverse().join('-')),
        'dd/MM/yyyy'
      )
    }
  },
  watch: {
    'onEdit.editting': {
      handler() {
        const listIndex = this.listItems.findIndex(
          ({ uid }) => uid === this.onEdit.uid
        )
        const beforeUpdateClone = JSON.parse(
          JSON.stringify(this.listItems[listIndex])
        )
        this.listItems[listIndex]._edit = this.onEdit.editting
      },
      deep: true
    }
  }
}
</script>

<style>
caption {
  padding: 0 !important;
  margin: 50px 0 0 0 !important;
  max-height: 40px;
}
caption div {
  margin: 0 !important;
  padding: 0 !important;
  display: inline-block;
}
caption div .search {
  width: 200px !important;
}
caption .custom-control-label {
  line-height: 32px !important;
  font-size: 14px !important;
  color: #ccc;
}
.lizard-column {
  overflow: auto !important;
}

.btn-outline-warning,
.btn-outline-success {
  min-width: 80px !important;
  max-height: 30px !important;
  padding: 0 7px !important;
  margin: 0 1px !important;
  line-height: 30px !important;
  border: 0 !important;
  border-radius: 2px !important;
  text-transform: uppercase !important;
  font-weight: bold !important;
}

.lizard-projects-tbl-cols {
  font-size: 12px !important;
  font-weight: bold !important;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;
}
th.lizard-projects-tbl-cols {
  text-wrap: pretty !important;
  line-height: 15px !important;
  vertical-align: middle !important;
}
.lizard-projects-tbl2-cols-actions {
  /* 5 */
  min-width: calc(100vw / 20) !important;
  max-width: calc(100vw / 20) !important;
}
.lizard-projects-tbl2-cols-code {
  /* 6.25 */
  min-width: calc(100vw / 16) !important;
  max-width: calc(100vw / 16) !important;
}
.lizard-projects-tbl2-cols-team {
  /* 4.54 * 7 = 31.78 */
  min-width: calc(100vw / 22) !important;
  max-width: calc(100vw / 22) !important;
}
.lizard-projects-tbl2-cols-dates {
  /* 6.25 * 3 = 18.75 */
  min-width: calc(100vw / 16) !important;
  max-width: calc(100vw / 16) !important;
}
.lizard-projects-tbl2-cols-utils {
  /* 7.69 * 3 = 23.07 */
  min-width: calc(100vw / 13) !important;
  max-width: calc(100vw / 13) !important;
}
</style>

<style scoped>
.actions {
  list-style: none;
  padding: 0;
  margin: 0;
}
.actions li {
  display: inline-block;
  margin: 0 5px;
}
.lizard-column-actions ul {
  padding: 0 !important;
  margin: 0 !important;
  list-style: none;
}
.lizard-column-actions ul li {
  display: inline-block;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 14px;
}
.lizard-color-btn {
  width: 38px;
  height: 34px;
  border: 2px solid #fff;
  border-radius: 3px;
  margin: 0 auto !important;
  padding: 0 !important;
  cursor: pointer;
  border: 0 none;
}
.lizard-color-btn:hover {
  border: 2px solid #666;
}
.rangeHolder {
  display: inline-block;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 3px;
  font-size: 10pt;
  font-weight: bold;
  letter-spacing: 0.03em;
  color: #fff;
  background-color: #000;
  text-shadow: #333 0 1px 2px !important;
}
</style>
