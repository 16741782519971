<template>
  <b-container fluid class="bg-white">
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-lg-12 text-center">
        <h4>
          <b-badge variant="warning"
            ><strong style="font-variant: small-caps">
              <b-icon icon="info-circle" font-scale="0.8"></b-icon>&nbsp;this is
              a beta feature. some functionality may not work as expected.
            </strong></b-badge
          >
        </h4>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <NewAssetBoard />
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </b-container>
</template>

<script>
import NewAssetBoard from './admin/asset-board-components/new-asset-board.vue'

export default {
  name: 'Beta',
  components: {
    NewAssetBoard
  }
}
</script>
