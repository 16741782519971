<template>
  <div v-if="ico" class="selected-project" @click="fn">
    <div>
      <b-icon :icon="ico" scale="2" />
    </div>
  </div>
  <div
    v-else
    class="selected-project"
    :style="
      tile.costLevelColor
        ? 'background: linear-gradient(180deg,' +
          tile.color +
          ' 80%, ' +
          tile.costLevelColor +
          ' 20%) !important'
        : 'background-color: ' + tile.color + ' !important'
    "
    :class="
      projectToDrag && projectToDrag.uid === tile.uid ? 'lizard-dragged' : ''
    "
    @click="dragProject()"
  >
    <div
      v-if="tile.costLevelValue"
      v-b-popover.hover.bottom="
        'Cost utilization to date: ' + tile.costLevelValue + '%'
      "
      :title="tile.name"
    >
      {{ tile.name }}
    </div>
    <div v-else title="Click to select">
      {{ tile.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleTile',
  props: {
    tile: {
      type: Object
    },
    ico: {
      type: String,
      default: null
    },
    fn: {
      type: Function
    }
  },
  computed: {
    Store() {
      return this.$store.state.components.staffing
    },
    projectToDrag() {
      return this.Store.projectToDrag
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    dragProject() {
      if (this.projectToDrag?.uid === this.tile.uid) {
        this.$store.commit('setStaffingProjectToDrag', null)
        return
      }
      this.$store.commit('setStaffingProjectToDrag', this.tile)
    }
  }
}
</script>

<style scoped>
.selected-project {
  width: calc(100% / 4) !important;
  max-width: 100px !important;
  max-height: fit-content !important;
  background-color: #999 !important;
  color: #fff !important;
  margin: 0 2px !important;
  text-align: center;
  font-size: 10pt;
  font-weight: 600;
  border-radius: 4px;
  border: 2px solid #eee;
  display: inline-block;
  text-overflow: ellipsis !important;
  overflow: hidden;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.selected-project:hover {
  cursor: default;
  box-shadow: #ccc 0px 0px 0px 2px !important;
  text-shadow: #ccc 0px 0px 4px !important;
  border: 2px solid #fff !important;
}
.selected-project div {
  width: 100%;
  height: 60px !important;
  max-height: 60px !important;
  margin: 0;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lizard-dragged {
  outline: 2px solid #fff !important;
  box-shadow: #000 0px 0px 8px !important;
  text-shadow: #ccc 0px 0px 6px !important;
}
</style>
