<template>
  <b-container fluid class="bg-white custom-font-size">
    <div class="row" style="background-color: rgba(255, 255, 255, 0.5)">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-12" style="background-color: rgba(255, 255, 255, 0.5)">
        <ProjectTiles />
      </div>
    </div>
    <div class="row" style="background-color: rgba(255, 255, 255, 0.5)">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <RolesSelector />
    </div>
    <div class="row" style="background-color: rgba(255, 255, 255, 0.5)">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" style="background-color: rgba(255, 255, 255, 0.5)">
      <div class="col-lg-2 lizard-no-margin left-switcher">
        <div
          class="lizard-week-switcher-btn"
          @click="prevWeek()"
          v-if="users.length"
        >
          previous week
        </div>
      </div>
      <div class="col-lg-8 month-holder">
        <div
          class="col-lg month-holder"
          @click="currentWeek()"
          v-if="users.length"
        >
          <div style="cursor: pointer" title="Click to fetch last updates">
            {{ monthInfo }}
          </div>
        </div>
        <div class="col-lg month-holder" v-else>
          No team members are currently in the selected roles.
        </div>
      </div>
      <div class="col-lg-2 lizard-no-margin right-switcher">
        <div
          class="lizard-week-switcher-btn"
          @click="nextWeek()"
          v-if="users.length"
        >
          next week
        </div>
      </div>
    </div>
    <div class="row" style="background-color: rgba(255, 255, 255, 0.5)">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row lizard-calendar">
      <div class="col-lg-12 lizard-calendar-holder">
        <StaffingCalendar
          :loading="loading"
          :users="users"
          :showWeekends="showWeekends"
        />
      </div>
    </div>
    <div class="row" style="background-color: rgba(255, 255, 255, 0.5)">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" style="background-color: rgba(255, 255, 255, 0.5)">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </b-container>
</template>

<script>
import { format as dateFormat, sub, add, differenceInDays } from 'date-fns'
import ServicesOld from '../../services/index.vue'
import StaffingCalendar from './Staffing/Calendar.vue'
import RolesSelector from './Staffing/RolesSelector.vue'
import ProjectTiles from './Staffing/ProjectTiles.vue'

export default {
  name: 'StaffingLogs',
  components: {
    StaffingCalendar,
    RolesSelector,
    ProjectTiles
  },
  computed: {
    Store() {
      return this.$store.state.components.staffing
    },
    users() {
      return this.Store.users.length ? this.Store.users : []
    },
    calendarWeeksStartDate() {
      return this.Store.calendarWeeksStartDate
    }
  },
  data() {
    return {
      loading: false,
      showWeekends: true,
      dataFetched: false,
      monthInfo: dateFormat(new Date(), 'MMMM yyyy')
    }
  },
  mounted() {
    this.tabMounted()
    this.$root.$on('get-staffing-assignments', async () => {
      await this.getStaffingAssignments()
      this.dataFetched = true
    })
    this.$root.$on('new-staffing-assignment', (data) =>
      this.updateRemoteDB(data)
    )
    this.$root.$on('remove-staffing-assignment', (data) =>
      this.updateRemoteDB(data, 'remove')
    )
  },
  methods: {
    tabMounted() {
      this.setDefaultCalendarWeeksStartDate()
      this.currentWeek()
    },
    prevWeek() {
      if (!this.calendarWeeksStartDate) {
        this.setDefaultCalendarWeeksStartDate()
      }
      this.$store.commit(
        'setStaffingCalendarWeeksStartDate',
        sub(new Date(this.calendarWeeksStartDate), {
          weeks: 1
        })
      )
      this.setCurrentMonth()
    },
    nextWeek() {
      if (!this.calendarWeeksStartDate) {
        this.setDefaultCalendarWeeksStartDate()
      }
      this.$store.commit(
        'setStaffingCalendarWeeksStartDate',
        add(new Date(this.calendarWeeksStartDate), {
          weeks: 1
        })
      )
      this.setCurrentMonth()
    },
    currentWeek() {
      this.setCurrentMonth()
    },
    setDefaultCalendarWeeksStartDate(setNull = false) {
      if (setNull) {
        this.$store.commit('setStaffingCalendarWeeksStartDate', null)
      } else {
        this.$store.commit('setStaffingCalendarWeeksStartDate')
      }
    },
    setCurrentMonth() {
      if (!this.calendarWeeksStartDate) {
        this.setDefaultCalendarWeeksStartDate()
      }
      const now = new Date()
      const startDate = this.calendarWeeksStartDate
      const isCurrentWeek = this.isWithinTwoWeeks(now, startDate)

      const monthsDays = this.countDaysByMonth(startDate)
      const selectedMonth = this.getFirstMonthWithMinDays(monthsDays, 7)
      const selectedYear = isCurrentWeek
        ? this.getYear(now)
        : this.getYear(startDate)
      this.monthInfo = selectedMonth + ' ' + selectedYear
    },
    async getStaffingAssignments() {
      try {
        if (this.dataFetched) return
        this.loading = true
        if (!this.calendarWeeksStartDate) {
          this.setDefaultCalendarWeeksStartDate()
        }
        const ownerRoles = this.users.reduce((acc, current) => {
          if (!acc.includes(current.role)) {
            acc.push(current.role)
          }
          return acc
        }, [])
        const { status = 500, data = {} } =
          await ServicesOld.GetStaffingAssignmentsForAdmin({
            fromDate: dateFormat(this.calendarWeeksStartDate, 'yyyy-MM-dd'),
            ownerRoles
          })
        if (status == 200) {
          this.dataFetched = true
          this.$root.$emit('staffing-assignments-fetched', data.content)
        }
        this.loading = false
      } catch (Exception) {
        console.log({ Exception })
      }
    },
    async updateRemoteDB(
      { userUid, date, projectUid, assignmentUid },
      action = 'add'
    ) {
      try {
        if (this.loading) return
        this.loading = true
        const { status, data } =
          action == 'add'
            ? await ServicesOld.SaveStaffingAssignment({
                userUid,
                date,
                projectUid
              })
            : await ServicesOld.RemoveStaffingAssignment({
                assignmentUid
              })
        if (status == 200) {
          this.$root.$emit('staffing-assignments-updated', {
            action,
            data: data.content
          })
        }
        this.loading = false
      } catch (Exception) {
        console.error(Exception?.message || Exception)
        // this.$bvToast.toast('Request Failed.', {
        //   title: 'Error',
        //   variant: 'danger',
        //   solid: true
        // })
        // console.error(Exception?.message || Exception)
        this.$root.$emit('staffing-assignments-updated')
        this.loading = false
      }
    },
    isWithinTwoWeeks(date1, date2) {
      const diffInDays = differenceInDays(add(date2, { days: 13 }), date1)
      return diffInDays > 0 && diffInDays < 14
    },
    countDaysByMonth(startDate) {
      const monthsDays = {}
      const start = new Date(startDate)
      const end = add(new Date(startDate), { days: 13 })

      const loopDate = new Date(start)
      while (loopDate <= end) {
        const month = this.getMonthName(loopDate)
        monthsDays[month] = (monthsDays[month] || 0) + 1
        loopDate.setDate(loopDate.getDate() + 1)
      }

      return monthsDays
    },
    getFirstMonthWithMinDays(monthsDays, minDays) {
      for (const month in monthsDays) {
        if (monthsDays[month] >= minDays) {
          return month
        }
      }
      return null
    },
    getMonthName(date) {
      try {
        return dateFormat(date, 'MMMM')
      } catch (error) {
        console.error({ error })
        return null
      }
    },
    getYear(date) {
      try {
        return dateFormat(date, 'yyyy')
      } catch (error) {
        console.error({ error })
        return null
      }
    }
  },
  watch: {
    calendarWeeksStartDate() {
      setTimeout(() => {
        this.dataFetched = false
        this.$root.$emit('get-staffing-assignments')
        this.$root.$emit('week-changed-on-staffing')
      }, 500)
    }
  }
}
</script>

<style scoped>
.lizard-week-switcher-btn {
  color: #fff;
  margin: 0;
  min-width: 100px !important;
  padding: 5px;
  text-align: center;
  font-size: 8pt;
  font-weight: bold;
  background-color: #036;
  text-transform: uppercase;
}
.lizard-week-switcher-btn.current-week {
  min-width: 100px !important;
  height: auto !important;
  margin: 0 auto !important;
}
.lizard-week-switcher-btn:hover {
  background-color: #036;
  color: #fff;
  cursor: pointer;
}
.lizard-week-switcher-btn:active {
  background-color: #036;
  color: #ccc;
}
.lizard-no-margin {
  margin: 0 !important;
  padding: 0 !important;
}
.lizard-no-margin.left-switcher div {
  text-align: center;
  float: left;
  margin-left: 2%;
}
.lizard-no-margin.right-switcher div {
  text-align: center;
  float: right !important;
  margin-right: 2%;
}
.month-holder {
  text-align: center;
  font-size: 16pt;
  font-weight: bold;
  color: #036;
}
</style>

<style>
.custom-font-size {
  font-size: 8px !important;
}
</style>
