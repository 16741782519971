<template>
  <b-container fluid class="bg-white">
    <div class="row" v-if="showSearchBar">
      <div class="col-sm-12">
        <b-form-input
          v-model="q"
          placeholder="Search by person name, email, project code, category or reference codes..."
        ></b-form-input>
      </div>
    </div>
    <div class="row" v-if="showFiltersToggleHandler">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="showFiltersToggleHandler">
      <div class="col-sm"></div>
      <div class="col-sm-3 text-right"><hr /></div>
      <div class="col-sm-1 text-center">
        <b-link
          class="filters-text active"
          v-if="showAdvancedFilters"
          @click="showAdvancedFilters = false"
          ><b-icon-caret-down-fill scale="0.9" />advanced filters</b-link
        >
        <b-link class="filters-text" @click="showAdvancedFilters = true" v-else
          ><b-icon-caret-up-fill scale="0.9" />advanced filters</b-link
        >
      </div>
      <div class="col-sm-3 text-left"><hr /></div>
      <div class="col-sm"></div>
    </div>
    <div class="row" v-if="showAdvancedFilters">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="showAdvancedFilters">
      <div class="col-lg-12" id="ExpensesFilters">
        <b-form-radio-group
          class="allowance-year-group"
          v-model="DateRange.dateLabel"
          :options="rangeEnums.dateLabel"
          @change="dateLabelChanged"
          button-variant="outline-secondary"
          name="allowance-years"
          buttons
        ></b-form-radio-group
        >&nbsp;
        <b-form-radio-group
          class="allowance-year-group"
          v-model="DateRange.year"
          :options="generateYears()"
          @change="yearChanged"
          button-variant="outline-secondary"
          name="allowance-years"
          buttons
        ></b-form-radio-group
        >&nbsp;
        <b-form-checkbox-group
          class="allowance-year-group"
          v-model="DateRange.months"
          :disabled="!DateRange.year"
          :options="generateMonths()"
          @change="monthChanged"
          button-variant="outline-secondary"
          name="allowance-years"
          buttons
        ></b-form-checkbox-group>
      </div>
    </div>
    <div class="row" v-if="showAdvancedFilters">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </b-container>
</template>

<script>
import { differenceInQuarters, format as dateFormat } from 'date-fns'

export default {
  name: 'FiltersBar',
  props: {
    rangeEnums: {
      type: Object,
      required: true
    },
    remoteFilters: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      showSearchBar: false,
      showFiltersToggleHandler: false,
      showAdvancedFilters: true
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    DateRange() {
      return this.Store.selectedExpenseDateRange
    }
  },
  mounted() {
    // const now = new Date()
    // const currentYear = this.DateRange?.year ?? now.getFullYear()
    // this.commitToStore('setSelectedExpenseDateRange', {
    //   year: currentYear
    // })
    // this.monthChanged()
    // this.dateLabelChanged(this.dateLabels[0])
  },
  methods: {
    commitToStore(commitName, value) {
      this.$store.commit(commitName, value)
    },
    generateYears() {
      const now = new Date()
      const currentYear = now.getFullYear()
      const sideYear =
        currentYear +
        (!Math.abs(
          differenceInQuarters(now, new Date(currentYear - 1, 1, 1, 0, 0, 0, 0))
        )
          ? 0
          : -1)
      return [sideYear, currentYear]
    },
    generateMonths() {
      const year = this.DateRange?.year ?? this.filters?.year ?? null
      if (!year) {
        return []
      }
      const now = new Date()
      const currentYear = now.getFullYear()
      const totalMonthsInYear = year == currentYear ? now.getMonth() + 1 : 12
      return Array.from({ length: totalMonthsInYear }, (v, k) => k + 1).map(
        (month) => {
          return dateFormat(new Date(year, month - 1, 1), 'MMMM')
        }
      )
    },
    dateLabelChanged(dateLabel) {
      this.commitToStore('setSelectedExpenseDateRange', {
        dateLabel
      })
    },
    yearChanged(year) {
      this.commitToStore('setSelectedExpenseDateRange', {
        year
      })
    },
    monthChanged(months = null) {
      if (!months) {
        months = this.generateMonths().reverse().slice(0, 3).reverse()
      }
      this.commitToStore('setSelectedExpenseDateRange', {
        months
      })
    }
  },
  watch: {
    remoteFilters: {
      handler(val) {
        const filterKeys = Object.keys(val)
        if (!filterKeys.length) {
          return
        }
        this.commitToStore('setSelectedExpenseDateRange', val)
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style>
#status-group label {
  background-color: #eee !important;
  color: #ccc !important;
}
#status-group label.focus {
  box-shadow: 0 0 0 transparent !important;
}
#status-group label.active:first-child {
  background-color: #ffc23d !important;
  color: #fff !important;
}
#status-group label.active:nth-child(2) {
  background-color: #1aa2b8 !important;
  color: #fff !important;
}
#status-group label.active:nth-child(3) {
  background-color: #dc3b45 !important;
  color: #fff !important;
}
#status-group label.active:last-child {
  background-color: #4aa746 !important;
  color: #fff !important;
}
.filters-text {
  font-size: 0.9rem;
  color: #ccc;
  font-weight: 600;
  font-variant: small-caps;
}
.filters-text:hover {
  text-decoration: none;
  color: #999;
}
.filters-text:active {
  color: #333;
}
.filters-text.active {
  color: #999;
}
#ExpensesFilters {
  display: block;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}
#ExpensesFilters .allowance-year-group {
  min-width: fit-content !important;
  line-height: 1 !important;
  margin: 0 !important;
}
#ExpensesFilters .allowance-year-group label {
  background-color: #eee !important;
  color: #ccc !important;
  height: 32px !important;
  min-height: 32px !important;
  line-height: 2 !important;
  vertical-align: middle !important;
  box-sizing: border-box;
  font-size: 75% !important;
  padding: auto 0 !important;
  border: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
#ExpensesFilters .allowance-year-group label:hover {
  background-color: #ccc !important;
  color: #eee !important;
}
#ExpensesFilters .allowance-year-group label.focus {
  box-shadow: 0 0 0 transparent !important;
}
#ExpensesFilters .allowance-year-group label.active {
  /* background-color: #17a2b8 !important; */
  background-color: #66ccff !important;
  color: #fff !important;
}
</style>
