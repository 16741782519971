<template>
  <b-container fluid class="bg-white">
    <div class="row" v-if="!loading">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="!loading">
      <filters-bar :range-enums="rangeEnums" :remote-filters="filters" />
    </div>
    <div class="row" v-if="!loading">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="!loading">
      <reports-box
        :expenseStatusOpts="expenseStatusOpts"
        :remote-filters="filters"
        :range-enums="rangeEnums"
      />
    </div>
    <div class="row">
      <div class="col-lg-12">
        <b-table
          caption-top
          responsive
          fixed
          striped
          small
          borderless
          selectable
          select-mode="range"
          selected-variant="info"
          @row-selected="rowsSelected"
          ref="expensesTable"
          table-class="lizard-activity-list lizard-expenses-box"
          thead-class="label"
          tbody-class="value"
          variant="secondary"
          :items="listItems"
          :fields="listFields"
          :busy="loading"
        >
          <template #table-caption
            ><b-badge
              variant="light"
              style="
                background-color: transparent;
                color: #999;
                text-transform: uppercase;
                font-size: 8pt;
              "
            >
              DISPLAYING {{ Pager.total }} RECORDS MATCHING SELECTED FILTERS
            </b-badge>
          </template>
          <template #cell(refCode)="data">
            <b-badge
              variant="info"
              v-if="data.item.refCode"
              style="cursor: default"
            >
              {{ data.item.refCode }}
            </b-badge>
          </template>
          <template #cell(projectCode)="data">
            <div
              v-if="data.item.project"
              v-b-tooltip.hover.bottom="{ variant: 'info' }"
              title="Click to filter list by project code"
              class="selected-project"
              style="margin-bottom: 5px"
              :style="
                'background-color: ' + data.item.project.color + ' !important'
              "
              @click="UpdateSearchQuery(data.item.project.projectCode)"
            >
              {{ data.item.project.title }}
            </div>
            <div
              v-else
              v-b-tooltip.hover.bottom="{ variant: 'info' }"
              title="Click to filter list by project code"
              class="selected-project"
              style="margin-bottom: 5px; background-color: #333"
              @click="UpdateSearchQuery('CANDESIC')"
            >
              CANDESIC
            </div>
          </template>
          <template #cell(owner)="data">
            <b-link
              @click="UpdateSearchQuery(data.item.owner.fullName || '')"
              style="color: #666 !important"
            >
              {{ data.item.owner.fullName || '-' }}
            </b-link>
          </template>
          <template #cell(category)="data">
            <b-link
              @click="UpdateSearchQuery(data.item.fee.type || '')"
              style="color: #666 !important"
            >
              {{ data.item.fee.type || '-' }}
            </b-link>
          </template>
          <template #cell(amount)="data"
            >{{ CurrencySymbol[data.item.fee.currency]
            }}{{ parseFloat(data.item.fee.amount).toFixed(2) }}</template
          >
          <template #cell(vat)="data"
            >{{ CurrencySymbol[data.item.fee.currency]
            }}{{ parseFloat(data.item.fee.vat).toFixed(2) }}</template
          >
          <template #cell(notes)="data"
            ><span>{{ summarize(data.item.notes) }}</span></template
          >
          <template #cell(updated)="data">
            {{ formatDate(data.item.updated) }}</template
          >
          <template #cell(created)="data">
            {{ formatDate(data.item.created) }}</template
          >
          <template #cell(expenseDate)="data">
            {{ formatDate(data.item.expenseDate) }}</template
          >
          <template #cell(attachments)="data">
            <ul class="lizard-attachment-list">
              <li v-if="data.item.attachments">
                <b-link @click="downloadReceipt(data.item.attachments)">
                  Download</b-link
                >
              </li>
            </ul>
          </template>
          <template #cell(status)="data">
            <b-form-select
              class="lizard-update-select"
              v-model="data.item.status"
              :options="expenseStatusOpts"
              @change="UpdateExpenseStatus(data.item)"
              size="sm"
            ></b-form-select> </template
        ></b-table>
        <b-pagination
          v-if="Pager.pages > 1 && Pager.total > 0"
          v-model="Pager.page"
          :total-rows="Pager.total"
          :per-page="Pager.limit"
          @change="changePage"
          aria-controls="expensesTable"
          size="sm"
          pills
          align="center"
        ></b-pagination>
      </div>
    </div>
    <div class="row" v-if="!loading">
      <div class="col-lg text-right">
        <download-excel
          :before-generate="generateExcel"
          :before-finish="resetExportData"
          :type="exportType"
          :name="exportName"
          :fields="exportFields"
          :data="exportData"
          :worksheet="exportWorksheet"
          v-if="exportExcel"
        >
          <b-button variant="outline-success" size="sm"
            ><b-icon
              icon="file-earmark-excel-fill"
              title="Export Excel"
            ></b-icon>
            Export Excel</b-button
          >
        </download-excel>
      </div>
    </div>
  </b-container>
</template>

<script>
import ServicesOld from '../../services/index.vue'
import Services from '../../services/main.service.vue'
import ReportsBox from './expense-components/reports-box.vue'
import FiltersBar from './expense-components/filters-bar.vue'
import { format as formatDate } from 'date-fns'

export default {
  name: 'Expenses',
  components: {
    ReportsBox,
    FiltersBar
  },
  data() {
    return {
      loading: false,
      showReportsPanel: true,
      q: null,
      listFields: [
        { key: 'created', label: 'Submitted Date', sortable: false },
        // { key: 'refCode', label: 'Reference Code' },
        { key: 'expenseDate', label: 'Expense Date', sortable: false },
        { key: 'projectCode', label: 'Project' },
        { key: 'category', label: 'Category', sortable: false },
        { key: 'owner', label: 'Person name', sortable: false },
        { key: 'manager', label: 'Manager', sortable: false },
        { key: 'amount', label: 'Amount', sortable: false },
        { key: 'vat', label: 'VAT', sortable: false },
        { key: 'notes', label: 'Notes' },
        { key: 'attachments', label: 'Attachments' },
        { key: 'status', label: 'Status', sortable: false },
        { key: 'updated', label: 'Last Action Date', sortable: false }
      ],
      listItems: [],
      filters: {},
      rangeEnums: {
        dateLabel: ['Filter by Submitted Date', 'Filter by Expense Date'],
        months: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ]
      },
      selectedItems: [],
      CurrencySymbol: {
        GBP: '£',
        USD: '$',
        EUR: '€'
      },
      expenseStatusOpts: [],
      exportExcel: true,
      exportType: 'xls',
      exportName: 'data.xls',
      exportWorksheet: "Candesic's Expenses Report",
      exportFields: {
        No: 'no',
        Name: 'name',
        Email: 'email',
        Amount: 'amount',
        VAT: 'vat',
        Currency: 'currency',
        'GBP Amount (£)': 'gbpamount',
        Manager: 'approvalManager',
        Category: 'category',
        Project: 'projectCode',
        Description: 'description',
        'Expense Date': 'date',
        'Submitted Date': 'submitted',
        Status: 'status',
        'Paid Date': 'paidDate'
      },
      exportData: []
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    Pager() {
      return this.Store.expensePager
    },
    DateRange() {
      return this.Store.selectedExpenseDateRange
    },
    SelectedStatusTags() {
      return this.Store.selectedExpenseStatus
    }
  },
  mounted() {
    this.q = null
    setTimeout(() => {
      this.getExpenses()
    }, 500)
  },
  methods: {
    changePage(page = 1) {
      this.$store.commit('setExpensePager', { page })
    },
    async fetchMetas() {
      this.loading = true
      const { status, data } = await Services.Projects.Admin.getMetas()
      if (status === 200) {
        const {
          expenses: { status: expensesList }
        } = data.content.metas
        this.expenseStatusOpts = expensesList
      }
      this.loading = false
    },
    async getExpenses(filters = {}) {
      this.loading = true
      await this.fetchMetas()
      filters.status = this.SelectedStatusTags.length
        ? this.SelectedStatusTags.join(',')
        : this.expenseStatusOpts.map((itm) => itm.value).join(',')
      const { data, status } = await ServicesOld.GetExpenses(
        {
          page: this.Pager?.page ?? 1,
          ...filters
        },
        true
      )
      if (status === 200) {
        const { info, list } = data.content
        const { limit, page, pages, total, query } = info
        this.$store.commit('setExpensePager', { limit, page, pages, total })
        this.filters = this.filterExpenses(query)
        // this.$store.commit('setExpenseStatusOptions', this.filters.status)
        this.listItems = list.map((itm) => {
          itm.status = itm.status.toUpperCase()
          itm.owner.fullName = `${itm.owner?.firstName ?? '?'} ${
            itm.owner?.lastName ?? '?'
          }`.trim()
          itm.manager = `${itm?.manager?.firstName ?? ''} ${
            itm?.manager?.lastName ?? ''
          }`.trim()
          return itm
        })
      }
      this.loading = false
    },
    filterExpenses(data = {}) {
      const DataKeys = Object.keys(data)
      if (!DataKeys.length) {
        return {}
      }
      data.months =
        typeof data.months === 'string' ? data.months.split(',') : data.months
      return {
        dateLabel: this.rangeEnums.dateLabel[data.dateLabel],
        year: data.year,
        months: data.months.map((month) => {
          return this.rangeEnums.months[month]
        }),
        status: data.status.split(',')
      }
    },
    currencySymbol(currency) {
      let result = ''
      switch (currency.toLowerCase()) {
        case 'usd':
          result = '$'
          break
        case 'eur':
          result = '€'
          break
        default:
          result = '£'
      }
      return result
    },
    categoryLabel(cid) {
      return this.categories[cid] ?? 'n/a'
    },
    calcAmount({ currency, amount }) {
      let toGBP = ''
      return (
        this.currencySymbol(currency) + parseFloat(amount).toFixed(2) + toGBP
      )
    },
    GetExpenseStatusVariant(status = 'pending') {
      let variant = 'light'
      switch (status.toLowerCase()) {
        case 'approved':
          variant = 'info'
          break
        case 'rejected':
          variant = 'danger'
          break
        case 'pending':
          variant = 'warning'
          break
        case 'paid':
          variant = 'success'
          break
        case 'failed':
          variant = 'dark'
          break
      }
      return variant
    },
    UpdateExpenseStatus({ uid, status }) {
      const bulkEditIsActive = this.selectedItems.length
      if (bulkEditIsActive) {
        this.selectedItems.forEach((uid, idx) => {
          ServicesOld.UpdateExpenseByAdmin(uid, {
            status
          }).then(() => {
            const itmIndex = this.listItems.findIndex((itm) => itm.uid === uid)
            this.listItems[itmIndex].status = status
            if (idx === bulkEditIsActive - 1) {
              this.selectedItems = []
              this.$refs.expensesTable.clearSelected()
            }
          })
        })
      } else {
        ServicesOld.UpdateExpenseByAdmin(uid, {
          status
        })
      }
    },
    async downloadReceipt(fileName) {
      if (fileName === null) {
        return
      }
      console.log({ fileName })
      return await ServicesOld.DownloadExpenseBill(fileName)
    },
    async removeBill(itm) {
      itm.attachments = null
    },
    async uploadBill(ev) {
      this.uploading = true
      const { data, status } = await ServicesOld.UploadExpenseBill(
        ev.target.files[0]
      )
      if (status === 200) {
        const itmId = ev.target.attributes['itmId'].value
        let itm = {}
        this.listItems.forEach((listItm) => {
          if (itmId === listItm.uid) {
            itm = listItm
          }
        })
        delete itm.action
        itm.attachments = data.content.fileName
        await ServicesOld.UpdateExpense(itmId, {
          attachmentKeys: itm.attachments
        })
        this.uploading = false
      } else {
        this.uploading = false
      }
    },
    rowsSelected(rows) {
      this.selectedItems = []
      rows.forEach((itm) => {
        this.selectedItems.push(itm.uid)
      })
    },
    summarize(note) {
      if (!note) {
        return ''
      }
      note = note.trim()
      if (note.length > 20) {
        return note.substring(0, 20) + '...'
      } else {
        return note
      }
    },
    async generateExcel() {
      try {
        this.exportName = `expenses-report.${this.exportType}`
        this.exportData = []

        const filters = { ...this.filters }
        filters.status = this.SelectedStatusTags.length
          ? this.SelectedStatusTags.join(',')
          : this.expenseStatusOpts.map((itm) => itm.value).join(',')

        const { status, data } = await ServicesOld.GetExpenseExcelReports({
          dateLabel: this.rangeEnums.dateLabel.findIndex(
            (label) => label === filters.dateLabel
          ),
          months: filters.months
            .map((month) =>
              this.rangeEnums.months.findIndex((mn) => mn === month)
            )
            .join(','),
          year: filters.year,
          status: filters.status
        })
        this.exportData = status === 200 ? data.content : []
      } catch (Exception) {
        console.log('ERRROR"')
        console.log(Exception)
      }
    },
    resetExportData() {
      this.exportName = `data.${this.exportType}`
      this.exportData = []
    },
    formatDate(dt) {
      return formatDate(new Date(dt), 'dd/MM/yyyy')
    }
  },
  watch: {
    DateRange: {
      handler(val) {
        const keys = Object.keys(val)
        if (!keys.length) {
          return
        }
        if (JSON.stringify(val) !== JSON.stringify(this.filters)) {
          this.getExpenses({
            dateLabel: this.rangeEnums.dateLabel.findIndex(
              (label) => label === val.dateLabel
            ),
            months: val.months
              .map((month) =>
                this.rangeEnums.months.findIndex((mn) => mn === month)
              )
              .join(','),
            year: val.year
          })
        }
      },
      immediate: true
    },
    SelectedStatusTags: {
      handler() {
        if (Object.keys(this.filters).length) {
          this.getExpenses({
            dateLabel: this.rangeEnums.dateLabel.findIndex(
              (label) => label === this.DateRange.dateLabel
            ),
            months: this.DateRange.months
              .map((month) =>
                this.rangeEnums.months.findIndex((mn) => mn === month)
              )
              .join(','),
            year: this.DateRange.year
          })
        }
      },
      immediate: true
    },
    Pager: {
      handler(nxt, prv) {
        const prevPage = prv?.page ?? 1
        const nextPage = nxt?.page ?? 1
        if (prevPage !== nextPage && Object.keys(this.filters).length) {
          this.getExpenses({
            dateLabel: this.rangeEnums.dateLabel.findIndex(
              (label) => label === this.DateRange.dateLabel
            ),
            months: this.DateRange.months
              .map((month) =>
                this.rangeEnums.months.findIndex((mn) => mn === month)
              )
              .join(','),
            year: this.DateRange.year
          })
        }
      },
      immediate: true
    }
  }
}
</script>

<style>
.cost-details-btn {
  font-size: 9pt !important;
  font-weight: bold;
  color: #6cf;
}
.lizard-column-fees {
  font-family: 'Courier New', Courier, monospace !important;
  font-size: 12px !important;
  font-weight: 100 !important;
}
.lizard-column-fees-disabled {
  color: #999;
}
.custom-link {
  color: '#999' !important;
}
.custom-link:hover {
  color: '#666' !important;
  text-decoration: underline !important;
}
</style>

<style scoped>
.lizard-expense-status {
  font-variant: small-caps;
  font-weight: bolder;
  font-size: 12pt;
  letter-spacing: 0.03em;
  padding: 0 8px 4px 8px;
  margin: 0 auto;
}
.lizard-expense-status:hover {
  cursor: pointer;
}
.export-excel-button {
  font-size: 10pt;
  font-weight: bold;
  text-align: center;
  border: 2px solid rgb(40, 128, 5);
  border-radius: 2px;
  background-color: transparent;
  color: rgb(40, 128, 5);
  margin: 10px 0;
  padding: 2px;
  width: 10%;
  max-width: 10%;
  float: right;
}
.export-excel-button:hover {
  background-color: rgb(40, 128, 5, 0.6);
  color: #fff;
  cursor: pointer;
}
.export-excel-button:active {
  background-color: rgb(40, 128, 5, 0.8);
  color: #ccc;
}
.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  font-weight: bold !important;
}
.lizard-column-index {
  text-align: center !important;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-reports-stats li {
  display: inline-block;
}
.lizard-hourly-rate-input {
  text-align: center;
  width: 60px;
  max-width: 60px;
}
.lizard-date-input {
  text-align: center;
  width: 90px;
  max-width: 90px;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
</style>
