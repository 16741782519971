<template>
  <b-container fluid class="bg-white">
    <div class="row">
      <div class="col-lg-12">
        <b-table
          fixed
          striped
          small
          borderless
          responsive
          caption-top
          sort-icon-left
          no-local-sorting
          table-class="lizard-activity-list lizard-candesic-table"
          thead-class="label"
          tbody-class="value"
          :sticky-header="stickyHeader"
          :busy.sync="loading"
          :items="listItems"
          :fields="listFields"
          :per-page="Pager.perPage"
          :current-page="Pager.page"
          @sort-changed="sortChanged"
        >
          <template #table-caption>
            <span
              v-if="loading"
              style="
                text-transform: uppercase;
                font-size: 10px;
                font-weight: bold;
              "
              ><strong>
                <b-spinner small></b-spinner>
                &nbsp;Loading...</strong
              >
            </span>
            <span
              v-else
              style="
                text-transform: uppercase;
                font-size: 10px;
                font-weight: bold;
                opacity: 0.7;
              "
              ><strong>
                total results based on selected filters:
                {{ listItems.length }} | page {{ Pager.page }}/{{
                  Math.ceil(listItems.length / Pager.perPage)
                }}
              </strong></span
            >
          </template>
          <template #cell(asset)="data">
            <span v-if="isInEditMode(data.item, 'asset')">
              <b-form-input
                v-model="data.item.asset"
                size="sm"
                class="lizard-assetboard-input-fld"
                autofocus
                @blur="toggleEditMode(data.item, 'asset')"
                @keyup.enter="toggleEditMode(data.item, 'asset')"
              />
            </span>
            <span v-else @click="toggleEditMode(data.item, 'asset')">{{
              data.item.asset || '-'
            }}</span>
            <!-- <span v-else
              ><b-link
                v-b-tooltip.hover.bottom="{ variant: 'dark' }"
                :href="getItemLink('onenote', data.item)"
                target="_blank"
                :title="
                  'Click to visit the ' + data.item.asset + '\'s OneNote page'
                "
                @click="toggleEditMode(data.item, 'asset')"
              >
                {{ data.item.asset }}
              </b-link></span
            > -->
          </template>
          <template #cell(pitchbook)="data">
            <b-link
              v-if="data.item.meta && data.item.meta.links.pitchbook"
              :href="getItemLink('pitchbook', data.item)"
              target="_blank"
              v-b-tooltip.hover.bottom="{ variant: 'dark' }"
              :title="'Click to visit the ' + data.item.asset + '\'s Pitchbook'"
            >
              Pitchpack
            </b-link>
          </template>
          <template #cell(mmlink)="data">
            <b-link
              v-if="data.item.meta && data.item.meta.links.mm"
              :href="getItemLink('mm', data.item)"
              target="_blank"
              v-b-tooltip.hover.bottom="{ variant: 'dark' }"
              :title="
                'Click to visit the ' +
                data.item.asset +
                '\'s Merger market profile'
              "
            >
              MM
            </b-link>
          </template>
          <template #cell(weblink)="data">
            <b-link
              v-if="data.item.meta && data.item.meta.links.website"
              :href="getItemLink('website', data.item)"
              target="_blank"
              v-b-tooltip.hover.bottom="{ variant: 'dark' }"
              :title="'Click to visit the ' + data.item.asset + '\'s Webpage'"
            >
              WEB
            </b-link>
          </template>
          <template #cell(partners)="data">
            <div
              class="lizard-user-avatars-list"
              v-b-tooltip.hover.bottom="{ variant: 'dark' }"
              :title="partner"
              v-for="(partner, index) in data.item.partners"
              v-if="partner.length"
              :key="index"
            >
              {{ getUserInitials(partner).text }}
            </div>
          </template>
          <template #cell(ems)="data">
            <div
              class="lizard-user-avatars-list"
              v-b-tooltip.hover.bottom="{ variant: 'dark' }"
              :title="em"
              v-for="(em, index) in data.item.ems"
              v-if="em.length"
              :key="index"
            >
              {{ getUserInitials(em, initialsColors[1]).text }}
            </div>
          </template>
          <template #cell(launch)="data">
            <span v-if="isInEditMode(data.item, 'launch')">
              <b-form-input
                v-model="data.item.launch"
                size="sm"
                class="lizard-assetboard-input-fld"
                autofocus
                @blur="toggleEditMode(data.item, 'launch')"
                @keyup.enter="toggleEditMode(data.item, 'launch')"
              />
            </span>
            <span
              v-else
              v-b-tooltip.hover.bottom="{ variant: 'dark' }"
              :title="data.item.launch"
              @click="toggleEditMode(data.item, 'launch')"
              >{{ data.item.launch || '-' }}</span
            >
          </template>
          <template #cell(description)="data">
            <span v-if="isInEditMode(data.item, 'description')">
              <b-form-input
                v-model="data.item.description"
                size="sm"
                class="lizard-assetboard-input-fld"
                autofocus
                @blur="toggleEditMode(data.item, 'description')"
                @keyup.enter="toggleEditMode(data.item, 'description')"
              />
            </span>
            <span
              v-else
              v-b-tooltip.hover.bottom="{ variant: 'dark' }"
              :title="data.item.description"
              @click="toggleEditMode(data.item, 'description')"
              >{{ data.item.description || '-' }}</span
            >
          </template>
          <template #cell(comments)="data">
            <span v-if="isInEditMode(data.item, 'comments')">
              <b-form-input
                v-model="data.item.comments"
                size="sm"
                class="lizard-assetboard-input-fld"
                autofocus
                @blur="toggleEditMode(data.item, 'comments')"
                @keyup.enter="toggleEditMode(data.item, 'comments')"
              />
            </span>
            <span
              v-else
              v-b-tooltip.hover.bottom="{ variant: 'dark' }"
              :title="data.item.comments"
              @click="toggleEditMode(data.item, 'comments')"
              >{{ data.item.comments || '-' }}</span
            >
          </template>
          <template #cell(ebitda)="data">
            <span v-if="isInEditMode(data.item, 'ebitda')">
              <b-form-input
                v-model="data.item.ebitda"
                size="sm"
                class="lizard-assetboard-input-fld"
                autofocus
                @blur="toggleEditMode(data.item, 'ebitda')"
                @keyup.enter="toggleEditMode(data.item, 'ebitda')"
              />
            </span>
            <span
              v-else
              v-b-tooltip.hover.bottom="{ variant: 'dark' }"
              :title="data.item.ebitda"
              @click="toggleEditMode(data.item, 'ebitda')"
              >{{ data.item.ebitda || '-' }}</span
            >
          </template>
          <template #cell(subsector)="data">
            <span
              v-b-tooltip.hover.bottom="{ variant: 'dark' }"
              :title="data.item.subsector.label"
            >
              {{ data.item.subsector.label }}
            </span>
          </template>
          <template #cell(status)="data">
            <span
              v-b-tooltip.hover.bottom="{ variant: 'dark' }"
              :title="data.item.status.label"
            >
              {{ data.item.status.label }}
            </span>
          </template>
          <template #cell(priority)="data">
            <span
              v-b-tooltip.hover.bottom="{ variant: 'dark' }"
              :title="data.item.priority.label"
            >
              {{ data.item.priority.label }}
            </span>
          </template>
          <template #cell(deck)="data">
            <span
              v-b-tooltip.hover.bottom="{ variant: 'dark' }"
              :title="data.item.deck.label"
            >
              {{ data.item.deck.label }}
            </span>
          </template>
          <template #cell(owner)="data">
            <span v-if="isInEditMode(data.item, 'owner')">
              <b-form-input
                v-model="data.item.owner"
                size="sm"
                class="lizard-assetboard-input-fld"
                autofocus
                @blur="toggleEditMode(data.item, 'owner')"
                @keyup.enter="toggleEditMode(data.item, 'owner')"
              />
            </span>
            <span
              v-else
              v-b-tooltip.hover.bottom="{ variant: 'dark' }"
              :title="data.item.owner"
              @click="toggleEditMode(data.item, 'owner')"
              >{{ data.item.owner || '-' }}</span
            >
          </template>
          <template #cell(ibank)="data">
            <span v-if="isInEditMode(data.item, 'ibank')">
              <b-form-input
                v-model="data.item.ibank"
                size="sm"
                class="lizard-assetboard-input-fld"
                autofocus
                @blur="toggleEditMode(data.item, 'ibank')"
                @keyup.enter="toggleEditMode(data.item, 'ibank')"
              />
            </span>
            <span
              v-else
              v-b-tooltip.hover.bottom="{ variant: 'dark' }"
              :title="data.item.ibank"
              @click="toggleEditMode(data.item, 'ibank')"
              >{{ data.item.ibank || '-' }}</span
            >
          </template>
        </b-table>
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-lg">
        <b-pagination
          v-model="Pager.page"
          :total-rows="listItems.length"
          :per-page="Pager.perPage"
          @change="changePage"
          aria-controls="activityTable"
          size="sm"
          pills
          align="center"
        ></b-pagination>
      </div>
    </div>
  </b-container>
</template>

<script>
import Services from '../../../services/main.service.vue'

export default {
  name: 'TableView',
  props: {
    listItems: {
      type: Array,
      required: true
    },
    enums: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      initialsColors: ['#50b4ff', '#b57de3'],
      stickyHeader: false,
      listFields: [
        {
          key: 'asset',
          label: 'Asset',
          thClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-asset text-center',
          tdClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-asset text-left',
          stickyColumn: false,
          sortable: true
        },
        {
          key: 'partners',
          label: 'Partners',
          thClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-persons',
          tdClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-persons'
        },
        {
          key: 'ems',
          thClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-persons',
          tdClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-persons',
          label: 'P/EM'
        },
        {
          key: 'subsector',
          thClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-labels',
          tdClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tagbox lizard-assetboard-tbl-cols-labels',
          label: 'Subsector',
          sortable: true
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
          thClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-labels',
          tdClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tagbox lizard-assetboard-tbl-cols-labels'
        },
        {
          key: 'priority',
          label: 'Priority',
          sortable: true,
          thClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-labels',
          tdClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tagbox lizard-assetboard-tbl-cols-labels'
        },
        {
          key: 'deck',
          label: 'Deck',
          sortable: true,
          thClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-labels',
          tdClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tagbox lizard-assetboard-tbl-cols-labels'
        },
        {
          key: 'launch',
          label: 'Launch',
          thClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-other',
          tdClass: 'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-other'
        },
        {
          key: 'ibank',
          label: 'IBank',
          thClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-other',
          tdClass: 'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-other'
        },
        {
          key: 'description',
          label: 'Decription',
          thClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-comments',
          tdClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-comments text-center'
        },
        {
          key: 'comments',
          label: 'Comments',
          thClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-comments',
          tdClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-comments text-center'
        },
        // {
        //   key: 'owner',
        //   label: 'Owners',
        //   thClass: 'lizard-col-owners text-center',
        //   tdClass: 'lizard-col-owners text-center'
        // },
        {
          key: 'ebitda',
          label: 'Est EBITDA',
          thClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-other',
          tdClass: 'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-other'
        },
        {
          key: 'owner',
          label: 'Owner',
          thClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-owner',
          tdClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-owner',
          sortable: false
        },
        {
          key: 'hqcountry',
          label: 'HQ Country',
          thClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-other',
          tdClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-other',
          sortable: true
        },
        {
          key: 'pitchbook',
          label: 'Pitchpack',
          thClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-mm text-center',
          tdClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-mm text-left',
          stickyColumn: false,
          sortable: false
        },
        {
          key: 'mmlink',
          label: 'MM',
          thClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-mm text-center',
          tdClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-mm text-center',
          stickyColumn: false,
          sortable: false
        },
        {
          key: 'weblink',
          label: 'Web',
          thClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-mm text-center',
          tdClass:
            'lizard-assetboard-tbl-cols lizard-assetboard-tbl-cols-mm text-center',
          stickyColumn: false,
          sortable: false
        }
        // {
        //   key: 'sector',
        //   label: 'Sector',
        //   thClass: 'lizard-col-sector text-center',
        //   tdClass: 'lizard-col-sector text-center'
        // }
      ],
      editMode: {
        key: null,
        uid: null,
        oldVal: null
      }
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    Pager() {
      return this.Store.assetBoardPager
    }
  },
  methods: {
    commitToStore(commitName, value) {
      this.$store.commit(commitName, value)
    },
    changePage(page) {
      this.commitToStore('setAssetBoardPager', { page })
    },
    getUserInitials(name, color = this.initialsColors[0]) {
      if (!name) {
        return ''
      }
      const names = name.split(' ')
      if (names.length === 1) {
        return names[0].charAt(0).toUpperCase()
      } else {
        return {
          text:
            names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase(),
          color
        }
      }
    },
    getItemLink(key, item) {
      return item.meta?.links?.[key] ?? '#'
    },
    addFilter(key, value = null) {
      if (!value) {
        return
      }
      this.$root.$emit('add_filter', { key, value })
    },
    sortChanged({ sortBy, sortDesc }) {
      this.commitToStore('setAssetBoardSort', { sortBy, sortDesc })
      this.$root.$emit('fetch_table_view_data')
    },
    isInEditMode(item, key) {
      return this.editMode.key === key && this.editMode.uid === item.uid
    },
    async toggleEditMode(item, key) {
      if (item.uid !== this.editMode.uid) {
        this.editMode = { key: null, uid: null, oldVal: this.editMode.oldVal }
      }
      if (this.editMode.uid === null) {
        this.editMode.oldVal = { uid: item.uid, val: item[key] }
      }
      this.editMode = this.isInEditMode(item, key)
        ? { key: null, uid: null, oldVal: this.editMode.oldVal }
        : { key, uid: item.uid, oldVal: this.editMode.oldVal }

      // Save
      if (
        !this.isInEditMode(item, key) &&
        this.editMode.oldVal.val !==
          this.listItems.find((i) => i.uid === item.uid)[key] &&
        this.editMode.oldVal.uid === item.uid
      ) {
        await this.updateAsset(item.uid, { [key]: item[key] })
        this.editMode.oldVal = null
      }
    },
    async updateAsset(uid, data) {
      return await Services.Projects.Admin.updateAssetBoard(uid, data)
    }
  }
}
</script>

<style scoped>
div.lizard-user-avatars-list {
  /* width: 20px; */
  /* height: 20px; */
  /* line-height: 20px; */
  /* border-radius: 50%; */
  /* background-color: #50b4ff; */
  display: inline-block;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  color: #333;
  cursor: default;
  margin: 0 1px;
  /* text-shadow: #666 0 1px 0; */
}
</style>

<style>
.lizard-assetboard-tbl-cols {
  font-size: 10px !important;
  font-weight: bold !important;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;
}
.lizard-assetboard-tbl-cols-asset {
  /* 6.25 */
  min-width: calc(100vw / 16) !important;
  max-width: calc(100vw / 16) !important;
}
.lizard-assetboard-tbl-cols-mm {
  /* 3.3 * 2 = 6.6 */
  min-width: calc(100vw / 30) !important;
  max-width: calc(100vw / 30) !important;
}
.lizard-assetboard-tbl-cols-persons {
  /* 4.16% x 2 = 8.33 */
  min-width: calc(100vw / 24) !important;
  max-width: calc(100vw / 24) !important;
}
.lizard-assetboard-tbl-cols-labels {
  /* 4% x 4 = 16 */
  min-width: calc(100vw / 25) !important;
  max-width: calc(100vw / 25) !important;
}
.lizard-assetboard-tbl-cols-comments {
  /* 12.5% * 2 = 25 */
  min-width: calc(100vw / 8) !important;
  max-width: calc(100vw / 8) !important;
}
.lizard-assetboard-tbl-cols-other {
  /* 5% x 4 = 20 */
  min-width: calc(100vw / 20) !important;
  max-width: calc(100vw / 20) !important;
}
.lizard-assetboard-tbl-cols-owner {
  /* 5% x 1 = 5 */
  min-width: calc(100vw / 20) !important;
  max-width: calc(100vw / 20) !important;
}
.lizard-assetboard-tagbox {
  color: #fff;
}
</style>
