<template>
  <b-container fluid>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-lg-12 text-center">
        <FitersBar />
      </div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-sm-12">
        <b-table
          caption-top
          responsive
          fixed
          striped
          small
          borderless
          table-class="lizard-activity-list lizard-expenses-box"
          thead-class="label"
          tbody-class="value"
          variant="secondary"
          :items="listItems"
          :fields="listFields"
          :per-page="pager.perPage"
          :current-page="pager.currentPage"
        >
          <template #table-caption
            >Requests (You can view and update allowance summaries by clicking
            on names.)</template
          >
          <template #cell(submitted)="data">
            {{ formatDate(data.item.submitted) }}
          </template>
          <template #cell(fullname)="data">
            <b-link @click="showLeaveStats(data.item.owner)">
              {{ data.item.owner.firstName }}
              {{ data.item.owner.lastName }}
            </b-link>
          </template>
          <template #cell(startDate)="data">
            {{ formatDate(data.item.startDate) }}
          </template>
          <template #cell(endDate)="data">
            {{ formatDate(data.item.endDate) }}
          </template>
          <template #cell(notes)="data">
            <span v-b-popover.hover.top="data.item.comments.user">
              {{ summarize(data.item.comments.user || '') }}
            </span>
          </template>
          <template #cell(comment)="data">
            <b-link
              v-b-popover.hover.top="data.item.comments.manager"
              @click="toggleCommentModal(data.item)"
              :style="
                data.item.comments.manager ? 'color: #333' : 'color: #999'
              "
              title="Click to change"
            >
              {{
                summarize(data.item.comments.manager || '') || 'Click to add'
              }}
            </b-link>
          </template>
          <template #cell(status)="data">
            <!--<b-form-select
              class="lizard-update-select"
              v-model="data.item.status"
              :options="holidayStatusOpts"
              @change="UpdateHolidayStatus(data.item)"
              size="sm"
            ></b-form-select> -->
            <h5>
              <b-badge
                class="lizard-holiday-status"
                :variant="GetHolidayStatusVariant(data.item.status)"
                @click="UpdateHolidayStatus(data.item)"
              >
                {{ data.item.status }}
              </b-badge>
            </h5></template
          ><template #cell(refCode)="data">
            <b-badge
              variant="info"
              v-if="data.item.refCode"
              style="cursor: default"
            >
              {{ data.item.refCode }}
            </b-badge>
          </template></b-table
        >
        <b-pagination
          v-model="pager.currentPage"
          :total-rows="listItems.length"
          :per-page="pager.perPage"
          aria-controls="activityTable"
          size="sm"
          pills
          align="center"
        ></b-pagination>
      </div>
    </div>
    <div class="row" v-if="!listItems.length">
      <div class="col-sm-5"></div>
      <div class="col-sm-2">
        <b-badge variant="secondary"
          >There isn't any records to show here!</b-badge
        >
      </div>
      <div class="col-sm-5"></div>
    </div>
    <b-modal
      v-b-modal.modal-footer-sm
      v-model="allowanceModal.show"
      centered
      hide-footer
      size="xl"
      :title="allowanceModal.data.title"
      :header-bg-variant="allowanceModal.headerBgVariant"
      :header-text-variant="allowanceModal.headerTextVariant"
      :body-bg-variant="allowanceModal.bodyBgVariant"
      :body-text-variant="allowanceModal.bodyTextVariant"
      :footer-bg-variant="allowanceModal.footerBgVariant"
      :footer-text-variant="allowanceModal.footerTextVariant"
    >
      <b-container fluid>
        <HolidayStatsBox :statsData="allowanceModal.data" />
      </b-container>
    </b-modal>
    <b-modal
      v-b-modal.modal-footer-sm
      v-model="commentModal.show"
      centered
      :title="commentModal.data.title"
      :header-bg-variant="commentModal.headerBgVariant"
      :header-text-variant="commentModal.headerTextVariant"
      :body-bg-variant="commentModal.bodyBgVariant"
      :body-text-variant="commentModal.bodyTextVariant"
      :footer-bg-variant="commentModal.footerBgVariant"
      :footer-text-variant="commentModal.footerTextVariant"
    >
      <b-container fluid>
        <b-row class="mb-1">
          <b-col>
            <b-textarea
              trim
              no-resize
              rows="4"
              v-model="commentModal.data.commentData.comment"
              placeholder="add your comment..."
            ></b-textarea>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="info"
            size="sm"
            class="float-right"
            @click="submitComment"
          >
            Submit
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import ServicesOld from '../../services/index.vue'
import Services from '../../services/main.service.vue'
import { format as dateFormat, subDays } from 'date-fns'
import HolidayStatsBox from './holiday-components/Stats.vue'
import FitersBar from './holiday-components/Filters.vue'

export default {
  name: 'HolidayDashboard',
  components: {
    FitersBar,
    HolidayStatsBox
  },
  data() {
    return {
      q: null,
      pager: {
        perPage: 25,
        currentPage: 1
      },
      listFields: [
        { key: 'submitted', label: 'Submitted date', sortable: true },
        // { key: 'refCode', label: 'Reference Code' },
        { key: 'fullname', label: 'Person name', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'startDate', label: 'From', sortable: true },
        { key: 'endDate', label: 'To', sortable: true },
        { key: 'totalDays', label: 'Days requested', sortable: true },
        { key: 'notes', label: 'Notes' },
        { key: 'comment', label: 'Manager comment' },
        { key: 'status', label: 'Status', sortable: true }
      ],
      listItems: [],
      clonedItems: [],
      holidayStatusOpts: [],
      allowanceModal: {
        show: false,
        headerBgVariant: 'info',
        headerTextVariant: 'light',
        bodyBgVariant: 'light',
        bodyTextVariant: 'dark',
        footerBgVariant: 'light',
        footerTextVariant: 'dark',
        data: {
          loading: true,
          title: 'Please wait...',
          years: [],
          allowance: {}
        }
      },
      commentModal: {
        show: false,
        headerBgVariant: 'info',
        headerTextVariant: 'light',
        bodyBgVariant: 'light',
        bodyTextVariant: 'dark',
        footerBgVariant: 'light',
        footerTextVariant: 'dark',
        data: {
          loading: true,
          commentData: {
            uid: null,
            comment: ''
          }
        }
      },
      holidayStatusDics: ['pending', 'approved', 'rejected'],
      metas: {}
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    reportsFilters() {
      return this.Store.leavesFilters
    }
  },
  mounted() {
    this.$root.$on('tab_holidays_selected', this.tabMounted)
  },
  methods: {
    async tabMounted() {
      if (!this.reportsFilters.dates?.length) {
        const date1 = new Date()
        date1.setDate(1)
        // date1.setMonth(date1.getMonth() - 1)
        date1.setHours(0, 0, 0, 0)
        const date2 = new Date()
        date2.setDate(1)
        date2.setMonth(date2.getMonth() + 2)
        date2.setHours(23, 59, 59, 999)
        this.reportsFilters.dates = [
          dateFormat(date1, 'yyyy-MM-dd'),
          dateFormat(subDays(date2, 1), 'yyyy-MM-dd')
        ]
      }
      this.q = null
      setTimeout(() => {
        if (this.clonedItems.length === 0) {
          this.GetList()
        }
      }, 500)
    },
    async GetList() {
      this.listItems = []
      this.clonedItems = []
      await this.fetchMetas()
      // Filters
      const {
        user: { uid: userUid },
        dateLabel: datesType,
        dates
      } = this.reportsFilters
      const response = await ServicesOld.GetHolidayList({
        userUid,
        datesType,
        dates
      })
      this.listItems = response.data.content.map((item) => {
        if (item.type.toLowerCase() === 'half-day' && item.meridiem) {
          item.type = item.type + ' (' + item.meridiem.toUpperCase() + ')'
        }
        item.submitted = item.submitted ? new Date(item.submitted) : null
        item.startDate = item.startDate ? item.startDate : null
        item.endDate = item.endDate ? new Date(item.endDate) : null
        item.status = item.status.toUpperCase()
        return item
      })
      this.clonedItems = [...this.listItems]
    },
    async GetHolidayAllowencesByUser({ uid }) {
      const response = await ServicesOld.GetHolidayAllowencesByUser(uid)
      return response.data.content
    },
    async fetchMetas() {
      const { status, data } = await Services.Projects.Admin.getMetas()
      if (status === 200) {
        const {
          holidays: { status: holidaysList }
        } = data.content.metas
        this.holidayStatusOpts = holidaysList
      }
    },
    showLeaveStats(owner) {
      this.allowanceModal.data.loading = true
      this.allowanceModal.data.title = `${owner.firstName}'s allowances`
      this.allowanceModal.data.years = []
      this.GetHolidayAllowencesByUser(owner)
        .then((data) => {
          this.allowanceModal.show = true
          this.allowanceModal.data.loading = false
          this.allowanceModal.data.years = Object.keys(data)
          this.allowanceModal.data.user = owner.uid
          this.allowanceModal.data.allowance = data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    GetHolidayStatusVariant(status) {
      let variant = 'light'
      switch (status.toLowerCase()) {
        case 'approved':
          variant = 'success'
          break
        case 'rejected':
          variant = 'danger'
          break
        case 'pending':
          variant = 'warning'
          break
        case 'failed':
          variant = 'dark'
          break
      }
      return variant
    },
    async UpdateHolidayStatus({ uid, status }) {
      const itemIndex = this.list.findIndex((item) => item.uid === uid)
      try {
        // this.list[itemIndex].status = 'please wait...'
        const response = await ServicesOld.UpdateHoliday(uid, { status })
        if (response.data.status) {
          // // change item status
          this.list[itemIndex].status =
            this.holidayStatusDics[
              this.holidayStatusDics.indexOf(status.toLowerCase()) + 1
            ]?.toUpperCase() ?? this.holidayStatusDics[0]?.toUpperCase()
          // this.GetList()
        } else {
          this.list[itemIndex].status = 'failed'
          this.$bvToast.toast('Please try again later', {
            title: 'Error',
            variant: 'danger',
            solid: true
          })
          setTimeout(() => {
            this.list[itemIndex].status = status
          }, 500)
        }
      } catch (error) {
        error = error?.response?.data?.error?.details?.validationErrors ?? [
          error?.message ?? ''
        ]
        if (error.length) {
          this.list[itemIndex].status = 'failed'
          this.$bvToast.toast(error, {
            title: 'Error',
            variant: 'danger',
            solid: true
          })
          setTimeout(() => {
            this.list[itemIndex].status = status
          }, 500)
        } else {
          console.error({ error })
        }
      }
    },
    toggleCommentModal(itm) {
      this.commentModal.data.loading = true
      this.commentModal.data.commentData = {}
      if (!this.commentModal.show) {
        this.commentModal.data.title = 'Loading...'
        this.commentModal.data.commentData = {
          uid: itm.uid,
          comment: itm.comments?.manager ?? ''
        }
        this.commentModal.data.title = `Comment for ${itm.owner.firstName}'s request`
      } else {
        this.commentModal.data.title = 'Default'
      }
      this.commentModal.data.loading = false
      this.commentModal.show = !this.commentModal.show
    },
    async submitComment() {
      const { uid, comment } = this.commentModal.data.commentData
      const itemIndex = this.listItems.findIndex((item) => item.uid === uid)
      const response = await ServicesOld.UpdateHoliday(uid, { comment })
      if (response.data.status) {
        // change item status
        this.listItems[itemIndex].comments.manager = comment
      } else {
        this.$bvToast.toast('Please try again later', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
        setTimeout(() => {
          this.listItems[itemIndex].comments.manager = null
        }, 500)
      }
      this.toggleCommentModal()
    },
    async UpdateAllowance() {
      const now = new Date()
      const { allowancesData, uid } = this.allowancesModal.data
      let leaveAllowances = {}
      leaveAllowances[now.getFullYear()] = allowancesData.reduce((acc, itm) => {
        for (const group in itm.groups) {
          acc[group] = Number(itm.groups[group])
        }
        return acc
      }, {})
      await Services.Users.Admin.updateUser({
        uid,
        data: {
          leaveAllowances
        }
      })
      this.allowancesModal.data.allowancesData =
        await this.GetHolidayAllowencesByUser({ uid })
    },
    formatDate(date) {
      return dateFormat(new Date(date), 'dd/MM/yyyy')
    },
    summarize(note) {
      note = note.trim()
      if (note.length > 20) {
        return note.substring(0, 20) + '...'
      } else {
        return note
      }
    }
  },
  watch: {
    q: {
      handler(q) {
        if (q && q.trim().length > 1) {
          const keywords = q
            .trim()
            .split(' ')
            .map((keyword) => keyword.trim())
          this.listItems = this.clonedItems.filter((itm) => {
            const fullName = `${itm?.owner?.firstName?.trim() ?? ''} ${
              itm?.owner?.lastName?.trim() ?? ''
            }`
            const ownerEmail = itm?.owner?.email?.trim()?.split('@')?.[0] ?? ''
            const refCode = itm?.refCode?.trim() ?? ''
            return keywords.every((keyword) => {
              const qRegEx = new RegExp(keyword, 'i')
              return (
                qRegEx.test(fullName) ||
                qRegEx.test(ownerEmail) ||
                qRegEx.test(refCode)
              )
            })
          })
        } else {
          this.listItems = this.clonedItems
        }
      },
      deep: true
    },
    listItems: {
      handler() {
        this.list = this.listItems
      },
      deep: true
    },
    reportsFilters: {
      handler() {
        this.GetList()
      },
      deep: true
    }
  }
}
</script>

<style>
.cost-details-btn {
  font-size: 9pt !important;
  font-weight: bold;
  color: #6cf;
}
.lizard-column-fees {
  font-family: 'Courier New', Courier, monospace !important;
  font-size: 12px !important;
  font-weight: 100 !important;
}
.lizard-column-fees-disabled {
  color: #999;
}
</style>

<style scoped>
.allowence-table-header {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #999 !important;
  text-align: center;
}
.allowence-data-row {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #666 !important;
  text-align: center;
  min-height: 40px;
  line-height: 40px;
}
.allowence-data-row .allowence-input {
  border: 0;
  background: transparent;
  font-size: 12px !important;
  font-weight: bold !important;
  color: #666 !important;
  min-height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0;
  padding: 0;
}
.allowence-data-row:hover {
  background-color: rgba(102, 204, 255, 0.1);
}

.lizard-holiday-status {
  font-variant: small-caps;
  font-weight: bolder;
  font-size: 10pt !important;
  letter-spacing: 0.03em;
  padding: 4px 8px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.lizard-holiday-status:hover {
  cursor: pointer;
}
.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  font-weight: bold !important;
}
.lizard-column-index {
  text-align: center !important;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-reports-stats li {
  display: inline-block;
}
.lizard-hourly-rate-input {
  text-align: center;
  width: 60px;
  max-width: 60px;
}
.lizard-date-input {
  text-align: center;
  width: 90px;
  max-width: 90px;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
</style>
