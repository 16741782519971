<script>
exports.ProjectsService = (api) => {
  const basePath = '/projects'
  const adminPath = `/admin${basePath}`

  const queryBuilder = (query = {}) => {
    let queryBuilder = []
    for (const key in query) {
      if (query[key]) {
        queryBuilder.push(`${key}=${query[key]}`)
      }
    }
    queryBuilder = queryBuilder.length ? '?' + queryBuilder.join('&') : ''
    return queryBuilder
  }

  const handleError = (error) => {
    throw new Error(error)
  }

  const getMondayProjects = async (refresh = false) => {
    try {
      return await api.get(
        `${basePath}/monday${refresh ? '?refresh=true' : ''}`
      )
    } catch (error) {
      handleError(error)
    }
  }

  const getMondayBdProjects = async (refresh = false) => {
    try {
      return await api.get(
        `${basePath}/monday/bds${refresh ? '?refresh=true' : ''}`
      )
    } catch (error) {
      handleError(error)
    }
  }

  const getList = async (cond = {}) => {
    try {
      return await api.get(`${basePath}/list${queryBuilder(cond)}`)
    } catch (error) {
      handleError(error)
    }
  }

  const getSummary = async ({ fromDate, toDate, owner }) => {
    try {
      return await api.get(
        `${adminPath}/summary/${fromDate}/${toDate}${
          owner ? '?owner=' + owner : ''
        }`
      )
    } catch (error) {
      handleError(error)
    }
  }

  const getCosts = async ({ projectUid: uid, fromDate, toDate }) => {
    try {
      return await api.get(
        `${adminPath}/uid/${uid}/costs${queryBuilder({ fromDate, toDate })}`
      )
    } catch (error) {
      handleError(error)
    }
  }

  const getCostExplorerReport = async ({ projectUid: uid }) => {
    try {
      return await api.get(`${adminPath}/uid/${uid}/explore`)
    } catch (error) {
      handleError(error)
    }
  }

  const getProjects = async () => {
    try {
      return await api.get(`${adminPath}/list`)
    } catch (error) {
      handleError(error)
    }
  }

  const getProjectsTracker = async ({ page = 1, perPage = 15 }) => {
    try {
      return await api.get(
        `${adminPath}/tracker/list${queryBuilder({ page, perPage })}`
      )
    } catch (error) {
      handleError(error)
    }
  }

  const getSingleProjectTracker = async (uid) => {
    try {
      return await api.get(`${adminPath}/tracker/uid/${uid}`)
    } catch (error) {
      handleError(error)
    }
  }

  const getMetas = async () => {
    try {
      return await api.get(`${adminPath}/metas`)
    } catch (error) {
      handleError(error)
    }
  }

  const create = async (data) => {
    try {
      return await api.post(adminPath, data)
    } catch (error) {
      handleError(error)
    }
  }

  const update = async (uid, data) => {
    try {
      return await api.put(`${adminPath}/uid/${uid}`, data)
    } catch (error) {
      handleError(error)
    }
  }

  const getClientPipeline = async (opts = {}) => {
    try {
      return await api.get(`${adminPath}/client-pipeline/${queryBuilder(opts)}`)
    } catch (error) {
      handleError(error)
    }
  }

  const createClientPipeline = async ({ client, asset, partner, em }) => {
    try {
      return await api.post(`${adminPath}/client-pipeline`, {
        client,
        asset,
        partner,
        em
      })
    } catch (error) {
      handleError(error)
    }
  }

  const getAssetBoard = async ({ monday = false, pager, filters, sort }) => {
    try {
      return await api.get(
        `${adminPath}/asset-board/${queryBuilder({
          monday,
          ...pager,
          ...filters,
          sort
        })}`
      )
    } catch (error) {
      handleError(error)
    }
  }

  const getAssetBoardEnums = async () => {
    try {
      return await api.get(`${adminPath}/asset-board/enums`)
    } catch (error) {
      handleError(error)
    }
  }

  const updateAssetBoard = async (uid, data) => {
    try {
      return await api.patch(`${adminPath}/asset-board/uid/${uid}`, data)
    } catch (error) {
      handleError(error)
    }
  }

  const searchAssetBoard = async (q, fld = 'asset') => {
    try {
      return await api.get(
        `${adminPath}/asset-board/?q=${encodeURIComponent(q)}&qfld=${fld}`
      )
    } catch (error) {
      handleError(error)
    }
  }

  const getBDAsset = async ({ monday = false }) => {
    try {
      return await api.get(`${adminPath}/bd-assets/${queryBuilder({ monday })}`)
    } catch (error) {
      handleError(error)
    }
  }

  const createBDAsset = async ({ asset, partner, em }) => {
    try {
      return await api.post(`${adminPath}/bd-assets`, {
        asset,
        partner,
        em
      })
    } catch (error) {
      handleError(error)
    }
  }

  const updateClientPipeline = async (uid, data) => {
    try {
      return await api.patch(`${adminPath}/client-pipeline/uid/${uid}`, data)
    } catch (error) {
      handleError(error)
    }
  }

  const deleteClientPipeline = async (uid) => {
    try {
      return await api.delete(`${adminPath}/client-pipeline/uid/${uid}`)
    } catch (error) {
      handleError(error)
    }
  }

  const updateBDAsset = async (uid, data) => {
    try {
      return await api.patch(`${adminPath}/bd-assets/uid/${uid}`, data)
    } catch (error) {
      handleError(error)
    }
  }

  const deleteBDAsset = async (uid) => {
    try {
      return await api.delete(`${adminPath}/bd-assets/uid/${uid}`)
    } catch (error) {
      handleError(error)
    }
  }

  return {
    getMondayProjects,
    getMondayBdProjects,
    getList,
    Admin: {
      getSummary,
      getCosts,
      getCostExplorerReport,
      getProjects,
      getProjectsTracker,
      getSingleProjectTracker,
      getMetas,
      create,
      update,
      getClientPipeline,
      createClientPipeline,
      getAssetBoard,
      getAssetBoardEnums,
      updateAssetBoard,
      searchAssetBoard,
      getBDAsset,
      createBDAsset,
      updateClientPipeline,
      deleteClientPipeline,
      updateBDAsset,
      deleteBDAsset
    }
  }
}
</script>
