<template>
  <b-container fluid class="bg-white">
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-sm-10 text-left header-msg">&nbsp;</div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-left">
        <b-card no-body>
          <b-tabs pills card class="tabs-space-ver">
            <b-tab
              v-for="tab in tabs.filter((tab) => tab.show)"
              :key="tab.id"
              :title="tab.title"
              :active="tab.active"
              lazy
              @click="tabChanged(tab.id)"
            >
              <component :is="tab.component" />
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </b-container>
</template>

<script>
import Services from '../../services/main.service.vue'
import Activities from './Activities.vue'
import DropTree from './DropTree.vue'
// import BetaFeature from './BetaFeature.vue'
// import OneNotePages from './OneNotePages.vue'

export default {
  name: 'AdminComponent',
  components: {
    Activities,
    DropTree
    // BetaFeature,
    // OneNotePages
  },
  data() {
    return {
      tabs: [
        {
          id: 'tab_activities',
          component: 'activities',
          title: 'Activities',
          active: true,
          show: true
        },
        {
          id: 'tab_onenotepages',
          component: 'one-note-pages',
          title: 'One Note Service',
          active: false,
          show: false
        },
        {
          id: 'tab_beta_feature',
          component: 'beta-feature',
          title: 'Beta Feature',
          active: false,
          show: false
        },
        {
          id: 'tab_drop_tree',
          component: 'drop-tree',
          title: 'Drop-Tree',
          active: false,
          show: false
        }
      ]
    }
  },
  mounted() {
    // this.$root.$on('parent_tab_superadmin_selected', this.tabMounted)
    const selectedTab = this.tabs.find((tab) => tab.active)
    this.tabChanged(selectedTab.id)
  },
  methods: {
    tabMounted() {
      const selectedTab = this.tabs.find((tab) => tab.active)
      this.tabChanged(selectedTab.id)
    },
    tabChanged(tabId) {
      Services.Users.fetchCurrentUser()
        .then(({ status, data }) => {
          if (status === 200) {
            this.$root.$emit(`${tabId}_selected`)
          } else {
            this.$root.$emit('user-signed-out')
          }
        })
        .catch(() => {
          this.$root.$emit('user-signed-out')
        })
    }
  }
}
</script>

<style>
.tabs-space-ver li {
  margin-bottom: 3px !important;
}
.tabs-space-ver li a.nav-link {
  background-color: transparent !important;
}
.tabs-space-ver li a.nav-link:hover {
  background-color: #666 !important;
  color: #eee !important;
}
</style>
