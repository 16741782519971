<template>
  <b-container fluid class="bg-white">
    <div class="row">
      <div class="col-lg-12">
        <b-table
          fixed
          small
          responsive
          borderless
          striped
          thead-class="tbl-header"
          tbody-class="tbl-body"
          :fields="calendarFields"
          :items="calendarItems"
        >
          <template #cell(project)="data">
            <div
              class="tbl-project-holder"
              :style="{
                'background-color': data.item.projectColor
              }"
            >
              <b-icon
                icon="x-circle-fill"
                style="cursor: pointer; opacity: 0.6"
                title="Remove Project"
                @click="removeProject(data.item.puid)"
              ></b-icon>
              <span v-b-popover.hover.bottom="data.item.project">
                {{ data.item.project }}
              </span>
            </div>
          </template>
          <template #cell(day_1)="data">
            <b-form-input
              class="tbl-body-block-timelog"
              type="number"
              min="0"
              max="24"
              :class="{
                'tbl-input-zero-val': data.item.day_1.hours === 0
              }"
              :disabled="loading"
              v-model="data.item.day_1.hours"
              v-if="isNotPassed(data.item.day_1.date)"
              @change="updateTimelog(data.item, 'day_1')"
            />
          </template>
          <template #cell(day_2)="data">
            <b-form-input
              class="tbl-body-block-timelog"
              type="number"
              min="0"
              max="24"
              :class="{
                'tbl-input-zero-val': data.item.day_2.hours === 0
              }"
              :disabled="loading"
              v-model="data.item.day_2.hours"
              v-if="isNotPassed(data.item.day_2.date)"
              @change="updateTimelog(data.item, 'day_2')"
            />
          </template>
          <template #cell(day_3)="data">
            <b-form-input
              class="tbl-body-block-timelog"
              type="number"
              min="0"
              max="24"
              :class="{
                'tbl-input-zero-val': data.item.day_3.hours === 0
              }"
              :disabled="loading"
              v-model="data.item.day_3.hours"
              v-if="isNotPassed(data.item.day_3.date)"
              @change="updateTimelog(data.item, 'day_3')"
            />
          </template>
          <template #cell(day_4)="data">
            <b-form-input
              class="tbl-body-block-timelog"
              type="number"
              min="0"
              max="24"
              :class="{
                'tbl-input-zero-val': data.item.day_4.hours === 0
              }"
              :disabled="loading"
              v-model="data.item.day_4.hours"
              v-if="isNotPassed(data.item.day_4.date)"
              @change="updateTimelog(data.item, 'day_4')"
            />
          </template>
          <template #cell(day_5)="data">
            <b-form-input
              class="tbl-body-block-timelog"
              type="number"
              min="0"
              max="24"
              :class="{
                'tbl-input-zero-val': data.item.day_5.hours === 0
              }"
              :disabled="loading"
              v-model="data.item.day_5.hours"
              v-if="isNotPassed(data.item.day_5.date)"
              @change="updateTimelog(data.item, 'day_5')"
            />
          </template>
          <template #cell(day_8)="data">
            <b-form-input
              class="tbl-body-block-timelog"
              type="number"
              min="0"
              max="24"
              :class="{
                'tbl-input-zero-val': data.item.day_8.hours === 0
              }"
              :disabled="loading"
              v-model="data.item.day_8.hours"
              v-if="isNotPassed(data.item.day_8.date)"
              @change="updateTimelog(data.item, 'day_8')"
            />
          </template>
          <template #cell(day_9)="data">
            <b-form-input
              class="tbl-body-block-timelog"
              type="number"
              min="0"
              max="24"
              :class="{
                'tbl-input-zero-val': data.item.day_9.hours === 0
              }"
              :disabled="loading"
              v-model="data.item.day_9.hours"
              v-if="isNotPassed(data.item.day_9.date)"
              @change="updateTimelog(data.item, 'day_9')"
            />
          </template>
          <template #cell(day_10)="data">
            <b-form-input
              class="tbl-body-block-timelog"
              type="number"
              min="0"
              max="24"
              :class="{
                'tbl-input-zero-val': data.item.day_10.hours === 0
              }"
              :disabled="loading"
              v-model="data.item.day_10.hours"
              v-if="isNotPassed(data.item.day_10.date)"
              @change="updateTimelog(data.item, 'day_10')"
            />
          </template>
          <template #cell(day_11)="data">
            <b-form-input
              class="tbl-body-block-timelog"
              type="number"
              min="0"
              max="24"
              :class="{
                'tbl-input-zero-val': data.item.day_11.hours === 0
              }"
              :disabled="loading"
              v-model="data.item.day_11.hours"
              v-if="isNotPassed(data.item.day_11.date)"
              @change="updateTimelog(data.item, 'day_11')"
            />
          </template>
          <template #cell(day_12)="data">
            <b-form-input
              class="tbl-body-block-timelog"
              type="number"
              min="0"
              max="24"
              :class="{
                'tbl-input-zero-val': data.item.day_12.hours === 0
              }"
              :disabled="loading"
              v-model="data.item.day_12.hours"
              v-if="isNotPassed(data.item.day_12.date)"
              @change="updateTimelog(data.item, 'day_12')"
            />
          </template>
        </b-table>
        <b-overlay
          :show="showPrompt"
          no-wrap
          @shown="onShown"
          @hidden="onHidden"
        >
          <template #overlay>
            <div
              v-if="loading"
              class="text-center p-4 bg-primary text-light rounded"
            >
              <div class="mb-3">Please wait...</div>
            </div>
            <div
              v-else
              ref="dialog"
              tabindex="-1"
              role="dialog"
              aria-modal="false"
              aria-labelledby="form-confirm-label"
              class="text-center p-3"
            >
              <h6>
                <strong
                  >Are you sure you want to remove this project and its timelogs
                  from the selected weeks?</strong
                >
              </h6>
              <div class="text-center">
                <b-button
                  variant="outline-danger"
                  class="mr-3"
                  @click="onCancel"
                >
                  Cancel
                </b-button>
                <b-button variant="outline-success" @click="onOK">OK</b-button>
              </div>
            </div>
          </template>
        </b-overlay>
      </div>
    </div>
    <div class="row" v-if="!showPrompt">
      <div class="col-lg text-right">
        <treeselect
          placeholder="Select a project to add..."
          v-model="newProjectSettings.val"
          :multiple="newProjectSettings.multiple"
          :clearable="newProjectSettings.clearable"
          :searchable="newProjectSettings.searchable"
          :open-on-click="newProjectSettings.openOnClick"
          :close-on-select="newProjectSettings.closeOnSelect"
          :limit="newProjectSettings.limit"
          :options="newProjectSettings.options"
        />
      </div>
      <div class="col-lg-6 text-right"></div>
      <div class="col-lg text-right">
        <b-button
          v-if="calendarItems.length"
          :variant="loading ? 'warning' : 'secondary'"
          size="sm"
          :disabled="loading || !logsInQueue.length"
          @click="_fn_runTimelogQueue()"
        >
          <span v-if="loading">auto-submitting...</span>
          <span v-if="logsInQueue.length">SUBMIT NEW CHANGES</span>
          <span v-else>CHANGES SUBMITTED AUTOMATICALLY</span>
        </b-button>
      </div>
    </div>
  </b-container>
</template>

<script>
import {
  addDays,
  format,
  isToday,
  isWeekend,
  isBefore,
  isSameDay
} from 'date-fns'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.min.css'

export default {
  name: 'TwoWeeksCalendarTimelog',
  components: {
    Treeselect
  },
  props: {
    showWeekends: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    projects: {
      type: Array,
      default: () => []
    },
    timelogs: {
      type: Array,
      default: () => []
    },
    _fn_runTimelogQueue: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    calendarWeeksStartDate() {
      return this.Store.calendarWeeksStartDate
    },
    calendarFields() {
      return this.Store.timelogCalendarFields
    },
    logsInQueue() {
      return this.Store.timelogsInQueue
    }
  },
  data() {
    return {
      weeks: {
        defaultDates: []
      },
      calendarItems: [],
      newProjectSettings: {
        value: null,
        multiple: false,
        clearable: true,
        searchable: true,
        openOnClick: true,
        closeOnSelect: true,
        limit: 1,
        options: []
      },
      showPrompt: false,
      projectToRemove: null
    }
  },
  watch: {
    calendarWeeksStartDate: {
      handler() {
        this.weeks.defaultDates = this.setDefaultDates()
        // Generate Calendar
        let dayIndex = 0
        this.$store.commit('setTimelogCalendarFields', [
          {
            key: 'project',
            label: 'Project',
            thClass: 'tbl-header-team',
            tdClass: 'tbl-body-team'
          },
          ...this.weeks.defaultDates.map((date) => {
            dayIndex++
            return {
              key: `day_${dayIndex}`,
              // label: `DAY ${dayIndex}`,
              label: this.formatHeaderDate(date),
              thClass: {
                'tbl-header-day-holder': true,
                today: this.isToday(date),
                weekend: this.isWeekend(date)
              },
              tdClass: {
                'tbl-body-block': true,
                weekend: this.isWeekend(date)
              }
            }
          })
        ])
        setTimeout(this.importTimelogs, 500)
      },
      deep: true
    },
    'newProjectSettings.val': {
      handler(val) {
        return this.selectNewProject(val)
      },
      deep: true
    }
  },
  methods: {
    selectNewProject(puid) {
      if (!puid) return
      const selectedProject = this.newProjectSettings.options.find(
        (prj) => prj.id === puid
      )
      // check if already exists
      const itemExists = this.calendarItems.find(
        (itm) => itm.puid === selectedProject.id
      )
      if (itemExists) {
        this.$bvToast.toast('Project already exists', {
          title: 'Notice',
          variant: 'warning',
          solid: true
        })
        return
      }
      this.calendarItems.push({
        puid: selectedProject.id,
        project: selectedProject.project,
        projectColor: selectedProject.color
      })
      let startDate = null
      this.calendarFields.forEach((field) => {
        if (field.key === 'project') return
        if (!startDate) {
          startDate = this.calendarWeeksStartDate
        } else {
          startDate = addDays(new Date(startDate), 1)
        }
        if (this.isWeekend(startDate)) return
        this.calendarItems[this.calendarItems.length - 1][field.key] = {
          date: startDate,
          hours: 0
        }
      })
      // this.newProjectSettings.options = this.newProjectSettings.options.filter(
      //   (prj) => prj.id !== puid
      // )
      this.newProjectSettings.val = null
    },
    setDefaultDates() {
      return new Array(14)
        .fill(0)
        .map((_, i) =>
          format(addDays(this.calendarWeeksStartDate, i), 'yyyy-MM-dd')
        )
        .filter((_, i) => this.showWeekends || i % 7 < 5)
    },
    isToday(date) {
      return isToday(new Date(date))
    },
    isWeekend(date) {
      return isWeekend(new Date(date))
    },
    isNotPassed(date) {
      return isBefore(new Date(date), new Date())
    },
    formatHeaderDate(date, cformat = 'EEE do') {
      return format(new Date(date), cformat)
    },
    importTimelogs() {
      const grouped = this.timelogs.reduce((acc, itm) => {
        acc[itm.project.uid] = acc[itm.project.uid] || []
        acc[itm.project.uid].push(itm)
        return acc
      }, {})
      const availableProjectsToSelect = [...this.projects]
        .filter((itm) => !grouped[itm.uid])
        .reduce((acc, itm) => {
          acc[itm.uid] = {
            id: itm.uid,
            puid: itm.uid,
            label: itm.title,
            project: itm.title,
            color: itm.meta.color
          }
          return acc
        }, {})
      this.newProjectSettings.options = Object.values(availableProjectsToSelect)

      this.calendarItems = new Array(Object.keys(grouped).length)
        .fill({})
        .map((_, idx) => {
          return {
            puid: Object.values(grouped)[idx][0].project.uid,
            project: Object.values(grouped)[idx][0].project.title,
            projectColor: Object.values(grouped)[idx][0].project.meta.color
          }
        })

      let startDate = null
      this.calendarFields.forEach((field) => {
        if (field.key === 'project') return
        if (!startDate) {
          startDate = this.calendarWeeksStartDate
        } else {
          startDate = addDays(new Date(startDate), 1)
        }
        if (this.isWeekend(startDate)) return
        this.calendarItems.forEach((itm) => {
          itm[field.key] = {
            date: startDate,
            hours: 0
          }
        })
      })
      const dayIndexReg = /^day_(\d+)$/
      this.timelogs.forEach((log) => {
        const projectIndex = this.calendarItems.findIndex(
          (itm) => itm.project === log.project.title
        )
        for (const key in this.calendarItems[projectIndex]) {
          if (
            dayIndexReg.test(key) &&
            isSameDay(
              new Date(this.calendarItems[projectIndex][key].date),
              new Date(log.date)
            )
          ) {
            this.calendarItems[projectIndex][key].hours = log.hours
          }
        }
      })
    },
    updateTimelog(item, dateId) {
      if (!this.isNotPassed(item[dateId].date)) return
      this.$store.commit('timelogToQueue', {
        project: item.puid,
        date: format(item[dateId].date, 'yyyy-MM-dd'),
        hours: parseFloat(item[dateId]?.hours ?? 0)
      })
    },
    removeProject(puid = this.projectToRemove, approved = false) {
      if (!approved) {
        this.projectToRemove = puid
        this.showPrompt = true
      } else {
        const item = this.calendarItems.find((itm) => itm.puid === puid)
        for (const key in item) {
          if (item[key].date) {
            this.$store.commit('timelogToRemoveQueue', {
              project: puid,
              date: format(item[key].date, 'yyyy-MM-dd')
            })
          }
        }
        this.calendarItems = this.calendarItems.filter(
          (itm) => itm.puid !== puid
        )
        this.projectToRemove = null
        this.showPrompt = false
      }
    },
    onShown() {
      this.$refs.dialog.focus()
    },
    onHidden() {
      this.$refs.submit.focus()
    },
    onCancel() {
      this.showPrompt = false
      this.projectToRemove = null
    },
    onOK() {
      this.removeProject(this.projectToRemove, true)
    }
  }
}
</script>

<style>
.vue-treeselect__single-value {
  font-size: 10pt;
}
.tbl-header {
  background-color: rgb(0, 123, 255);
  padding: 0 !important;
  font-weight: bold;
  font-size: 10pt;
  color: #fff;
  height: 40px;
  line-height: 40px;
  min-width: 100% !important;
  max-width: 100% !important;
}
.tbl-header-team {
  background-color: rgb(177, 177, 177) !important;
  padding: 0 !important;
  text-align: center;
  width: 9% !important;
  max-width: 9% !important;
}
.tbl-header-day-holder {
  text-align: center;
  padding: 0 !important;
  width: 6.5% !important;
  max-width: 6.5% !important;
}
.tbl-header-day-holder.today {
  background-color: rgb(0, 51, 102) !important;
  padding: 0 !important;
}
.tbl-header-day-holder.weekend {
  background-color: rgb(177, 177, 177) !important;
  padding: 0 !important;
}
.tbl-header-day-holder.weekend.today {
  color: rgb(0, 123, 255) !important;
}
.tbl-body {
  background-color: #eee;
  min-height: 100px;
  max-height: 100px;
  min-width: 100% !important;
  max-width: 100% !important;
  padding: 0 !important;
}
.tbl-body-team {
  color: #fff;
  background-color: #333;
  border-top: #999 solid 1px;
  font-size: 10pt;
  font-weight: bold;
  padding: 0 !important;
  text-align: center;
  height: 60px !important;
  line-height: 60px;
  max-height: 60px !important;
  width: 9% !important;
  max-width: 9% !important;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;
}
.tbl-body-block {
  text-align: center;
  padding: 0 !important;
  font-size: 10pt;
  font-weight: bold;
  height: 60px !important;
  line-height: 60px !important;
  max-height: 60px !important;
  width: 6.5% !important;
  max-width: 6.5% !important;
}
.tbl-body-block:hover {
  background-color: #ccc;
  cursor: default;
  padding: 0 !important;
}
.tbl-body-block.weekend {
  background-color: rgb(177, 177, 177) !important;
}
.tbl-body-block.highlighted {
  background-color: rgb(240, 240, 190) !important;
}
.tbl-project-holder {
  color: #fff;
  text-shadow: 1px 1px 1px #666;
}
.tbl-body-block-timelog {
  background-color: transparent !important;
  border: none !important;
  text-align: center !important;
  height: 60px !important;
  font-size: 10pt !important;
  font-weight: bold !important;
  color: #333 !important;
}
.tbl-body-block-timelog:focus {
  background-color: rgb(240, 240, 190) !important;
  outline-color: transparent !important;
  outline: none !important;
}
.tbl-body-block-timelog.tbl-input-zero-val {
  color: #999 !important;
}
</style>

<style scoped>
.calendar-header {
  background-color: rgb(0, 123, 255);
  padding: 0 !important;
  font-weight: bold;
  font-size: 10pt;
  color: #fff;
  height: 40px;
  line-height: 40px;
  min-width: 100% !important;
  max-width: 100% !important;
}
.calendar-header .team {
  background-color: rgb(177, 177, 177) !important;
  padding: 0 !important;
  text-align: center;
}
.calendar-header .loading {
  background-color: rgb(177, 50, 50) !important;
  color: #fff;
}
.calendar-header .day-holder {
  text-align: center;
  padding: 0 !important;
  width: 7% !important;
  max-width: 7% !important;
}
.calendar-header .day-holder.today {
  background-color: rgb(0, 51, 102) !important;
  padding: 0 !important;
}
.calendar-header .day-holder.weekend {
  background-color: rgb(177, 177, 177) !important;
  padding: 0 !important;
}
.calendar-header .day-holder.weekend.today {
  color: rgb(0, 123, 255) !important;
}
.calendar-body {
  background-color: #eee;
  min-height: 100px;
  max-height: 100px;
  min-width: 100% !important;
  max-width: 100% !important;
  padding: 0 !important;
}
.calendar-body.bgOdd {
  background-color: #f5f5f5 !important;
  padding: 0 !important;
}
.calendar-body .team {
  color: #fff;
  background-color: #333;
  border-top: #999 solid 1px;
  font-size: 10pt;
  font-weight: bold;
  display: inline-block;
  padding: 0 !important;
  text-align: center;
  max-height: 100px !important;
  overflow-y: auto !important;
}
.calendar-body .team ul {
  list-style: none;
  padding: 0 !important;
  margin: 0 5px !important;
}
.calendar-body .team ul li {
  margin: 5px 0;
}
.calendar-body .team ul li .remove-btn {
  font-weight: bold;
  padding: 6px 4px;
}
.calendar-body .team ul li .remove-btn:hover {
  cursor: pointer;
}
.calendar-body .block {
  text-align: center;
  display: block;
  overflow-x: hidden;
  overflow-y: scroll !important;
  padding: 0 !important;
  max-height: 100px !important;
  width: 7% !important;
  max-width: 7% !important;
}
.calendar-body .block.weekend {
  background-color: rgb(177, 177, 177) !important;
}
.calendar-body .block.highlighted {
  background-color: rgb(240, 240, 190) !important;
}
::-webkit-scrollbar {
  width: 0 !important;
}
.calendar-body .block:hover {
  background-color: #ccc;
  cursor: default;
  padding: 0 !important;
}
.calendar-body .block .project {
  margin: 5px;
  padding: 10px;
  font-size: 10pt;
  color: #fff;
  font-weight: bold;
  overflow-wrap: break-word;
  max-width: 100px !important;
}
.calendar-body .block .timelog {
  background-color: transparent;
  border: none;
  text-align: center;
  height: 100px;
  font-size: 12pt;
  font-weight: bold;
  color: #999;
}
.calendar-body .block .timelog:focus {
  color: #333;
}
.addProjectTxt {
  color: #fff;
}
.addProjectTxt:hover {
  color: #fff;
}
.lizard-timelog-project-select {
  width: 80%;
  margin: 0 auto;
}
.lizard-delete-overlay-message {
  font-size: 12pt;
  font-weight: bold;
  color: #333;
}
.lizard-logs-to-remove-list {
  list-style: none;
  margin: 20px 0;
  padding: 0;
}
.lizard-logs-to-remove-list li {
  margin: 0 5px;
  padding: auto;
  font-size: 10pt;
  display: inline-block;
  font-weight: 600;
  color: #666;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
