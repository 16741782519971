<template>
  <div class="row">
    <div class="col-lg">
      <div class="row">
        <div class="col-sm-1" v-if="activityType === 'hld'"></div>
        <div
          class="col-sm-3"
          v-if="activityType === 'exp'"
          style="text-align: right !important"
        >
          <label for="" class="h6" style="float: right !important"
            >Expense Date</label
          >
        </div>
        <div class="col-sm-2" v-if="activityType === 'hld'">
          <label for="" class="h6">Date</label>
        </div>
        <div class="col-sm" v-if="activityType === 'exp'">
          <el-date-picker
            v-model="selectedDates"
            type="date"
            placeholder="Pick a day"
            class="lizard-form-date"
            size="small"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            @change="expensePickerDateChanged"
          ></el-date-picker>
        </div>
        <div class="col-sm-7" v-if="activityType === 'hld'">
          <el-date-picker
            v-model="selectedDates"
            type="daterange"
            range-separator=""
            start-placeholder="From"
            end-placeholder="To"
            class="form-control lizard-form-datepicker"
            size="small"
            value-format="yyyy-MM-dd"
            :picker-options="holidayPickerOptions"
            @change="holidayPickerDateChanged"
            v-if="showDateRange"
          >
          </el-date-picker>
          <el-date-picker
            v-model="selectedDates"
            type="date"
            placeholder="Pick a day"
            class="lizard-form-date"
            size="small"
            value-format="yyyy-MM-dd"
            :picker-options="holidayPickerOptions"
            @change="holidayPickerDateChanged"
            v-else
          ></el-date-picker>
        </div>
        <div class="col-sm-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import ServicesOld from '../../services/index.vue'
import Services from '../../services/main.service.vue'
import { isWeekend, differenceInMonths, format as dateFormat } from 'date-fns'

export default {
  name: 'DateSelector',
  props: {
    activityType: String,
    dateRangeSelected: Boolean,
    yearsToEnable: Array
  },
  data() {
    return {
      selectedDates: null,
      showDateRange: true,
      pickerOptionsWithHistory: {
        firstDayOfWeek: 1,
        cellClassName(dt) {
          let customClass = 'no-activity-day'
          const isHoliday = [0, 6].indexOf(Vue.moment(dt).day()) > -1
          const afterToday = Vue.moment(dt).diff(Vue.moment()) >= 0
          const formatedDate = String(Vue.moment(dt).format('YYYY-MM-DD'))
          if (Object.keys(records).includes(formatedDate)) {
            let hours = parseFloat(records[formatedDate])
            customClass = hours >= 8 ? 'full-activity-day' : 'half-activity-day'
          }
          return isHoliday
            ? 'holiday-activity-day'
            : afterToday
            ? ''
            : customClass
        },
        disabledDate(dt) {
          // const isHoliday = [0, 6].indexOf(Vue.moment(dt).day()) > -1;
          return Vue.moment(dt).diff(Vue.moment()) > 0
        }
      },
      pickerOptions: {
        firstDayOfWeek: 1,
        cellClassName() {
          return ''
        },
        disabledDate(dt) {
          return Vue.moment(dt).diff(Vue.moment()) > 0
        }
      }
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    bankHolidays() {
      return this.Store.bankHolidays.data
    },
    approvedHolidays() {
      return this.Store.approvedHolidays
    },
    FormData() {
      return this.Store.formData
    },
    dates() {
      return this.FormData.dates
    },
    selectedHolidayType() {
      return this.FormData.holidayType
    },
    holidayPickerOptions() {
      const bankHolidays = this.bankHolidays
      return {
        firstDayOfWeek: 1,
        cellClassName(dt) {
          const isBankHoliday = bankHolidays.includes(
            dateFormat(new Date(dt), 'yyyy-MM-dd')
          )
          return isBankHoliday ? 'bank-holiday-day' : ''
        },
        disabledDate: (dt) => {
          const isBankHoliday = this.bankHolidays.includes(
            dateFormat(new Date(dt), 'yyyy-MM-dd')
          )
          const dtYear = new Date(dt).getFullYear()
          const now = new Date()
          const currentYear = now.getFullYear()
          const compareDate = new Date(currentYear + 1, 0, 1, 0, 0, 0, 0)
          const diff = 12 - Math.abs(differenceInMonths(now, compareDate))
          const sideYear = currentYear + (diff >= 2 ? 1 : -1)
          const duplicatedRequest = this.approvedHolidays.includes(
            dateFormat(new Date(dt), 'yyyy-MM-dd')
          )
          const sameYear = [currentYear, sideYear].includes(dtYear)
          return (
            isWeekend(new Date(dt)) ||
            isBankHoliday ||
            duplicatedRequest ||
            !sameYear
          )
        }
      }
    }
  },
  async mounted() {
    const { data } = await this.getBankHolidays()
    this.$store.commit(
      'cacheBankHolidays',
      (data?.content ?? []).map((bh) => bh.date)
    )
    this.selectedDates = this.dates
  },
  methods: {
    holidayPickerDateChanged(dt) {
      if (!dt) {
        return
      }
      dt = typeof dt === 'string' ? dt.split(',') : dt
      let totalBankHolidaysInDates = 0
      const start = new Date(dt[0])
      const end = new Date(dt[1] || dt[0])
      const loop = new Date(start)
      while (loop <= end) {
        const dt = dateFormat(new Date(loop), 'yyyy-MM-dd')
        const isBankHoliday = this.bankHolidays.includes(dt)
        if (isBankHoliday) {
          totalBankHolidaysInDates++
        }
        loop.setDate(loop.getDate() + 1)
      }
      this.$store.commit('modifyFormData', { key: 'dates', value: dt })
      this.$store.commit('modifyFormData', {
        key: 'totalBankHolidaysInDates',
        value: totalBankHolidaysInDates
      })
    },
    expensePickerDateChanged(dt) {
      this.$store.commit('modifyFormData', { key: 'dates', value: dt })
    },
    holidayTypeChanged() {
      this.$store.commit('modifyFormData', { key: 'dates', value: null })
      this.selectedDates = null
      this.showDateRange = !['HALFDAY', 'HALFPERSONAL', 'BIRTHDAY'].includes(
        this.selectedHolidayType
      )
    },
    async getBankHolidays() {
      return await ServicesOld.GetBankHolidays()
    }
  },
  watch: {
    selectedHolidayType: {
      handler: 'holidayTypeChanged',
      immediate: true
    }
  }
}
</script>

<style scoped>
.lizard-help-list {
  padding: 0 2px 2px 2px;
  margin: 1px;
  font-size: 12px;
  font-weight: bold;
  cursor: default;
  border-radius: 2px;
  line-height: 16px;
  font-variant: small-caps;
}
.lizard-help-icon {
  position: relative;
  float: right;
  display: block;
  top: -27px;
  right: 2px;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
</style>
