<template>
  <b-container fluid class="bg-white">
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-lg"></div>
      <div class="col-lg-11">
        <div class="row">
          <div class="col-lg-9 lizard-col-padding"></div>
          <div class="col-lg-3 lizard-col-padding text-right">
            <div
              v-b-tooltip.hover.bottom="{ variant: 'info' }"
              title="Clear all filters"
              @click="clearFilters"
              class="lizard-clear-filters-btn"
              :class="{ 'lizard-monday-sync': loadingAssetBoard }"
            >
              <b-icon icon="x-circle"></b-icon>&nbsp;Clear filters
            </div>
            <div
              v-if="HasAdminPerm"
              v-b-tooltip.hover.bottom="{ variant: 'info' }"
              title="Sync with Monday.com"
              class="lizard-monday-btn"
              :class="{ 'lizard-monday-sync': loadingAssetBoard }"
              @click="fetchFromMonday"
            >
              <img
                src="../../assets/monday-1.jpg"
                class="lizard-monday-button"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg"></div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <b-card bg-variant="light">
          <div class="row">
            <div class="col-lg-2 text-left lizard-col-padding">
              <span style="font-weight: bold; color: #999"
                ><b-icon-search font-scale="0.8"></b-icon-search>&nbsp;<span
                  style="font-variant: small-caps"
                  >asset</span
                >&nbsp;<span style="font-size: 8pt">(Search)</span>
              </span>
            </div>
            <div class="col-lg-2 text-left lizard-col-padding">
              <span style="font-weight: bold; color: #999"
                ><b-icon-list-ul font-scale="0.8"></b-icon-list-ul>&nbsp;<span
                  style="font-variant: small-caps"
                  >subsector</span
                >&nbsp;<span style="font-size: 8pt">(List)</span>
              </span>
            </div>
            <div class="col-lg-1 text-left lizard-col-padding">
              <span style="font-weight: bold; color: #999"
                ><b-icon-list-ul font-scale="0.8"></b-icon-list-ul>&nbsp;<span
                  style="font-variant: small-caps"
                  >status</span
                >&nbsp;<span style="font-size: 8pt">(List)</span>
              </span>
            </div>
            <div class="col-lg-1 text-left lizard-col-padding">
              <span style="font-weight: bold; color: #999"
                ><b-icon-list-ul font-scale="0.8"></b-icon-list-ul>&nbsp;<span
                  style="font-variant: small-caps"
                  >launch</span
                >&nbsp;<span style="font-size: 8pt">(List)</span>
              </span>
            </div>
            <div class="col-lg-2 text-left lizard-col-padding">
              <span style="font-weight: bold; color: #999"
                ><b-icon-list-ul font-scale="0.8"></b-icon-list-ul>&nbsp;<span
                  style="font-variant: small-caps"
                  >est. ebitda</span
                >&nbsp;<span style="font-size: 8pt">(Select)</span>
              </span>
            </div>
            <div class="col-lg-2 text-left lizard-col-padding">
              <span style="font-weight: bold; color: #999"
                ><b-icon-search font-scale="0.8"></b-icon-search>&nbsp;<span
                  style="font-variant: small-caps"
                  >ibank</span
                >&nbsp;<span style="font-size: 8pt">(Search)</span>
              </span>
            </div>
            <div class="col-lg-2 text-left lizard-col-padding">
              <span style="font-weight: bold; color: #999"
                ><b-icon-list-ul font-scale="0.8"></b-icon-list-ul>&nbsp;<span
                  style="font-variant: small-caps"
                  >hq country</span
                >&nbsp;<span style="font-size: 8pt">(Search)</span>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-2 text-left lizard-col-padding">
              <b-form-input
                size="sm"
                placeholder="Type and select..."
                v-model="assetFilter"
                @keyup.enter="selectFilterTag('asset')"
              ></b-form-input>
            </div>
            <div class="col-lg-2 text-left lizard-col-padding">
              <div
                class="d-flex justify-content-center mb-3"
                v-if="!metas.hqcountries"
              >
                <b-button size="sm" variant="light" disabled>
                  <b-spinner small type="grow" label="Loading..."></b-spinner>
                  Loading...
                </b-button>
              </div>
              <treeselect
                v-else
                placeholder="Type and select..."
                v-model="subsectorTreeSettings.val"
                :multiple="subsectorTreeSettings.multiple"
                :clearable="subsectorTreeSettings.clearable"
                :searchable="subsectorTreeSettings.searchable"
                :open-on-click="subsectorTreeSettings.openOnClick"
                :close-on-select="subsectorTreeSettings.closeOnSelect"
                :limit="subsectorTreeSettings.limit"
                :options="subsectorTreeSettings.options"
              />
            </div>
            <div class="col-lg-1 text-left lizard-col-padding">
              <div
                class="d-flex justify-content-center mb-3"
                v-if="!metas.assetsstatus"
              >
                <b-button size="sm" variant="light" disabled>
                  <b-spinner small type="grow" label="Loading..."></b-spinner>
                  Loading...
                </b-button>
              </div>
              <b-form-select
                v-else
                v-model="filters.status.val"
                :options="metas.assetsstatus"
                placeholder="Status..."
                size="sm"
                @change="selectFilterTag('status')"
              ></b-form-select>
            </div>
            <div class="col-lg-1 text-left lizard-col-padding">
              <div
                class="d-flex justify-content-center mb-3"
                v-if="!metas.assetsstatus"
              >
                <b-button size="sm" variant="light" disabled>
                  <b-spinner small type="grow" label="Loading..."></b-spinner>
                  Loading...
                </b-button>
              </div>
              <b-form-select
                v-else
                v-model="filters.launch.val"
                :options="metas.assetslaunchtags"
                placeholder="Launch..."
                size="sm"
                @change="selectFilterTag('launch')"
              ></b-form-select>
            </div>
            <div class="col-lg-2 text-left lizard-col-padding">
              <div class="d-flex justify-content-center mb-3">
                <vue-slider
                  v-model="filters.ebitda.range"
                  :width="slider.width"
                  :min="slider.minRange"
                  :max="slider.maxRange"
                  :height="slider.height"
                  :tooltip="slider.tooltip"
                  :tooltipPlacement="slider.tooltipPlacement"
                  :duration="slider.duration"
                  :lazy="slider.lazy"
                  :process-style="{ 'background-color': '#999' }"
                  @drag-end="SetEbitdaFilter"
                >
                  <template v-slot:tooltip="{ value }">
                    <div class="lizard-slider-tooltip">
                      {{ GetSliderTooltipValue(value) }}
                    </div>
                  </template>
                </vue-slider>
              </div>
            </div>
            <div class="col-lg-2 text-left lizard-col-padding">
              <b-form-input
                size="sm"
                placeholder="Type and select..."
                v-model="ibankFilter"
                @keyup.enter="selectFilterTag('ibank')"
              ></b-form-input>
            </div>
            <div class="col-lg-2 text-left lizard-col-padding">
              <b-form-input
                size="sm"
                placeholder="Type and select..."
                v-model="hqcountryFilter"
                @keyup.enter="selectFilterTag('hqcountry')"
              ></b-form-input>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-2 text-left lizard-col-padding">
              <ul class="filterTags">
                <li
                  v-if="Filters.asset.length"
                  v-for="(item, idx) in Filters.asset"
                  :key="idx"
                >
                  <b-badge
                    @click="removeFilterTag('asset', item)"
                    variant="info"
                    class="lizard-setting-badge"
                    title="Click to remove this filter"
                    v-b-tooltip.hover.bottom="{ variant: 'secondary' }"
                    ><b-icon icon="x-circle-fill"></b-icon>
                    {{ item }}
                  </b-badge>
                </li>
                <li
                  v-if="filters.asset.temp.length"
                  v-for="item in filters.asset.temp"
                  :key="item"
                >
                  <b-badge
                    @click="selectFilterTag('asset', item)"
                    variant="secondary"
                    class="lizard-setting-badge lizard-setting-badge-temp"
                    title="Click to add this filter"
                    v-b-tooltip.hover.bottom="{ variant: 'info' }"
                  >
                    {{ item }}
                  </b-badge>
                </li>
              </ul>
            </div>
            <div class="col-lg-2 text-left lizard-col-padding"></div>
            <div class="col-lg-1 text-left lizard-col-padding"></div>
            <div class="col-lg-1 text-left lizard-col-padding"></div>
            <div class="col-lg-2 text-left lizard-col-padding"></div>
            <div class="col-lg-2 text-left lizard-col-padding">
              <ul class="filterTags">
                <li
                  v-if="filters.ibank.list.length"
                  v-for="(item, idx) in filters.ibank.list"
                  :key="idx"
                >
                  <b-badge
                    @click="removeFilterTag('ibank', item)"
                    variant="info"
                    class="lizard-setting-badge"
                    title="Click to remove this filter"
                    v-b-tooltip.hover.bottom="{ variant: 'secondary' }"
                    ><b-icon icon="x-circle-fill"></b-icon>
                    {{ item }}
                  </b-badge>
                </li>
                <li
                  v-if="filters.ibank.temp.length"
                  v-for="item in filters.ibank.temp"
                  :key="item"
                >
                  <b-badge
                    @click="selectFilterTag('ibank', item)"
                    variant="secondary"
                    class="lizard-setting-badge lizard-setting-badge-temp"
                    title="Click to add this filter"
                    v-b-tooltip.hover.bottom="{ variant: 'info' }"
                  >
                    {{ item }}
                  </b-badge>
                </li>
              </ul>
            </div>
            <div class="col-lg-2 text-left lizard-col-padding">
              <ul class="filterTags">
                <li
                  v-if="filters.hqcountry.list.length"
                  v-for="(item, idx) in filters.hqcountry.list"
                  :key="idx"
                >
                  <b-badge
                    @click="removeFilterTag('hqcountry', item)"
                    variant="info"
                    class="lizard-setting-badge"
                    title="Click to remove this filter"
                    v-b-tooltip.hover.bottom="{ variant: 'secondary' }"
                    ><b-icon icon="x-circle-fill"></b-icon>
                    {{ item }}
                  </b-badge>
                </li>
                <li
                  v-if="filters.hqcountry.temp.length"
                  v-for="item in filters.hqcountry.temp"
                  :key="item"
                >
                  <b-badge
                    @click="selectFilterTag('hqcountry', item)"
                    variant="secondary"
                    class="lizard-setting-badge lizard-setting-badge-temp"
                    title="Click to add this filter"
                    v-b-tooltip.hover.bottom="{ variant: 'info' }"
                  >
                    {{ item }}
                  </b-badge>
                </li>
              </ul>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <table-view
          :loading="loadingAssetBoard"
          :listItems="listItems"
        ></table-view>
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </b-container>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import Services from '../../services/main.service.vue'
import TableView from './asset-board-components/table-view.vue'

export default {
  name: 'AssetBoard',
  components: {
    Treeselect,
    VueSlider,
    TableView
  },
  data() {
    return {
      loadingAssetBoard: false,
      loadingText: 'Loading...',
      loadingStatus: 'light',
      metas: {},
      listItems: [],
      ebitdaList: [],
      filters: {
        asset: {
          val: '',
          temp: [],
          list: []
        },
        subsector: {
          val: '',
          temp: [],
          list: []
        },
        status: {
          val: '',
          temp: [],
          list: []
        },
        ebitda: {
          list: [],
          range: [0, 50]
        },
        ibank: {
          val: '',
          temp: [],
          list: []
        },
        hqcountry: {
          val: '',
          temp: [],
          list: []
        },
        launch: {
          val: '',
          temp: [],
          list: []
        }
      },
      filterKeys: [],
      filterEbitda: '',
      subsectorTreeSettings: {
        value: null,
        multiple: true,
        clearable: true,
        searchable: true,
        openOnClick: true,
        closeOnSelect: true,
        limit: 3,
        options: [
          // {
          //   id: 'a',
          //   label: 'a',
          //   // children: [
          //   //   {
          //   //     id: 'aa',
          //   //     label: 'aa'
          //   //   },
          //   //   {
          //   //     id: 'ab',
          //   //     label: 'ab'
          //   //   }
          //   // ]
          // }
        ]
      },
      slider: {
        width: '85%',
        size: 1,
        minRang: 0,
        maxRange: 50,
        duration: 2,
        lazy: true,
        height: '14px',
        tooltip: 'always',
        tooltipPlacement: 'bottom'
      }
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    HasAdminPerm() {
      return this.$store.state.user.hasAdminPrivileges
    },
    Pager() {
      return this.Store.assetBoardPager
    },
    Filters() {
      return this.Store.assetBoardFilters
    },
    SortOrders() {
      return this.Store.assetBoardSort.join(',')
    },
    assetFilter: {
      get() {
        return this.Filters.asset && this.Filters.asset.length
          ? this.Filters.asset[0]
          : ''
      },
      async set(value) {
        if (this.loadingAssetBoard) return
        if (value.trim().length < 1) {
          this.filters.asset.temp = []
          return
        }
        let filter =
          this.listItems.find((item) => {
            const reg = new RegExp(`^${value}.+`, 'ig')
            return reg.test(item.asset)
          })?.asset ?? null
        if (!filter) {
          filter = await this.searchAssets(value)
        }
        this.filters.asset.temp = []
        if (filter) {
          if (
            !this.filters.asset.temp.includes(filter) &&
            !this.filters.asset.list.includes(filter)
          ) {
            this.filters.asset.temp.unshift(filter)
          }
        }
      }
    },
    subsectorFilter: {
      get() {
        return this.filters.subsector.val
      },
      set(value) {
        if (this.loadingAssetBoard) return
        if (value.trim().length < 2) {
          this.filters.subsector.temp = []
          return
        }
        const filter =
          this.metas.subsectors.find((item) => {
            const reg = new RegExp(`^${value}`, 'i')
            return reg.test(item.text)
          })?.value ?? null
        if (filter) {
          if (
            !this.filters.subsector.temp.includes(filter) &&
            !this.filters.subsector.list.includes(filter)
          ) {
            this.filters.subsector.temp.unshift(filter)
          }
        } else {
          this.filters.subsector.temp = []
        }
      }
    },
    statusFilter: {
      get() {
        return this.filters.status.val
      },
      set(value) {
        if (this.loadingAssetBoard) return
        if (value.trim().length < 1) {
          this.filters.status.temp = []
          return
        }
        const filter =
          this.metas.assetsstatus.find((item) => {
            const reg = new RegExp(`^${value}`, 'gi')
            return reg.test(item.text)
          })?.value ?? null
        this.filters.status.temp = []
        if (filter) {
          if (
            !this.filters.status.temp.includes(filter) &&
            !this.filters.status.list.includes(filter)
          ) {
            this.filters.status.temp.unshift(filter)
          }
        }
      }
    },
    ebitdaFilter: {
      get() {
        return this.filters.ebitda.list
      },
      set(list) {
        if (this.loadingAssetBoard) return
        let filters = {}
        filters['ebitda'] = list
        filters = Object.assign({}, this.Filters, filters)
        this.commitToStore('setAssetBoardFilters', filters)
        this.commitToStore('setAssetBoardPager', { page: 1 })
      }
    },
    ibankFilter: {
      get() {
        return this.Filters.ibank && this.Filters.ibank.length
          ? this.Filters.ibank[0]
          : ''
      },
      async set(value) {
        if (this.loadingAssetBoard) return
        if (value.trim().length < 1) {
          this.filters.ibank.temp = []
          return
        }
        let filter =
          this.listItems.find((item) => {
            const reg = new RegExp(`^${value}`, 'ig')
            return reg.test(item.ibank)
          })?.ibank ?? null
        if (!filter) {
          filter = await this.searchIBanks(value)
        }
        this.filters.ibank.temp = []
        if (filter) {
          if (
            !this.filters.ibank.temp.includes(filter) &&
            !this.filters.ibank.list.includes(filter)
          ) {
            this.filters.ibank.temp.unshift(filter)
          }
        }
      }
    },
    hqcountryFilter: {
      get() {
        return this.Filters.hqcountry && this.Filters.hqcountry.length
          ? this.Filters.hqcountry[0]
          : ''
      },
      set(value) {
        if (this.loadingAssetBoard) return
        if (value.trim().length < 1) {
          this.filters.hqcountry.temp = []
          return
        }
        this.metas.hqcountries.forEach((item) => {
          const reg = new RegExp(`^${value}`, 'gi')
          const val = item.value
          if (
            reg.test(item.text) &&
            !this.filters.hqcountry.temp.includes(val) &&
            !this.filters.hqcountry.list.includes(val) &&
            val.trim().length
          ) {
            this.filters.hqcountry.temp.unshift(val)
          }
        })
        this.filters.hqcountry.temp = this.filters.hqcountry.temp
          .filter((item) => {
            const reg = new RegExp(value, 'gi')
            return reg.test(item)
          })
          .sort((a, b) => a.localeCompare(b))
      }
    }
  },
  mounted() {
    this.tabMounted()
    // this.$root.$on('parent_tab_assetboard_selected', this.tabMounted)
    this.$root.$on('fetch_table_view_data', () => {
      this.fetchData({ monday: false })
    })
  },
  methods: {
    tabMounted() {
      this.fetchMetas()
      this.fetchData({ monday: false })
    },
    commitToStore(commitName, value, force = false) {
      this.loadingAssetBoard = true
      this.listItems = []
      this.$store.commit(commitName, value, force)
      this.fetchData({ monday: false })
    },
    fetchFromMonday() {
      if (this.loadingAssetBoard) {
        return
      }
      this.$bvToast.toast('Syncing with Monday.com...', {
        title: 'Please wait...',
        variant: 'info',
        solid: true
      })
      this.fetchData({ monday: true, notification: true })
        .then(() => {
          // Reset Pager
          this.commitToStore('setAssetBoardPager', { page: 1 })
        })
        .catch(() => {
          this.$bvToast.toast('Error syncing with Monday.com', {
            title: 'Error',
            variant: 'danger',
            solid: true
          })
        })
    },
    async fetchData({ monday = false, notification = false }) {
      try {
        this.loadingAssetBoard = true
        this.loadingStatus = 'secondary'
        this.loadingText = 'Please wait...'
        const { status, data } = await Services.Projects.Admin.getAssetBoard({
          monday,
          pager: this.Pager,
          filters: this.Filters,
          sort: this.SortOrders
        })
        if (status === 200) {
          this.loadingAssetBoard = false
          if (!data.content.list.length) {
            // this.$bvToast.toast('No data found based on filters', {
            //   title: 'Empty',
            //   variant: 'info',
            //   solid: true
            // })
            return
            // return this.fetchFromMonday()
          }
          this.ebitdaList = data.content.ebitdaList.map(
            ({ label: text, value }) => {
              return {
                text,
                value
              }
            }
          )
          const tblData = this.dataMounted(data.content)
          this.listItems = tblData
          this.loadingStatus = ''
          this.loadingText = ''
          if (notification) {
            this.$bvToast.toast('Synced with Monday.com', {
              title: 'Done!',
              variant: 'success',
              solid: true
            })
          }
        } else {
          this.loadingStatus = 'danger'
          this.loadingText =
            'Error fetching Asset Board, Please Try again later.'
          this.$bvToast.toast('Error fetching Asset Board', {
            title: 'Error',
            variant: 'danger',
            solid: true
          })
          this.loadingAssetBoard = false
        }
      } catch (error) {
        this.loadingAssetBoard = false
        throw error
      }
    },
    async fetchMetas() {
      const { status, data } = await Services.Projects.Admin.getMetas()
      if (status === 200) {
        const {
          assetBoard: {
            subsectors,
            assetsstatus,
            hqcountries,
            assetslaunchtags
          }
        } = data.content.metas
        this.metas = { subsectors, assetsstatus, hqcountries, assetslaunchtags }
        // this.metas.subsectors.unshift({
        //   value: '',
        //   text: 'Select one...'
        // })
        this.subsectorTreeSettings.options = this.metas.subsectors.map(
          ({ value, text, subcategories = [] }) => {
            const opts = {
              id: value,
              label: text
            }
            if (subcategories.length) {
              opts.children = subcategories.map(({ value, text }) => {
                return {
                  id: value,
                  label: text
                }
              })
            }
            return opts
          }
        )
        this.metas.assetsstatus.unshift({
          value: '',
          text: 'Select one...'
        })
        this.metas.hqcountries.unshift({
          value: '',
          text: 'Select one...'
        })
        this.metas.assetslaunchtags.unshift({
          value: '',
          text: 'Select one...'
        })
        this.metas.hqcountries.push({
          value: 'other',
          text: 'Other'
        })
      }
    },
    async searchAssets(q) {
      const { status, data } = await Services.Projects.Admin.searchAssetBoard(q)
      if (status === 200) {
        return data.content.list[0]?.asset ?? null
      }
      return null
    },
    async searchIBanks(q) {
      const { status, data } = await Services.Projects.Admin.searchAssetBoard(
        q,
        'ibank'
      )
      if (status === 200) {
        return data.content.list[0]?.ibank ?? null
      }
      return null
    },
    createCSSClass(className, rules) {
      // Create a new style element
      const style = document.createElement('style')
      // Construct the CSS rule as a string
      const cssRule = `.${className} { ${rules} }`
      // Check if the style sheet supports inserting rules directly
      if (style.styleSheet) {
        style.styleSheet.cssText = cssRule // For IE
      } else {
        style.appendChild(document.createTextNode(cssRule)) // For other browsers
      }
      // Append the style element to the document head
      document.head.appendChild(style)
    },
    dataMounted({ list = [], total = 0 }) {
      list = list.map((item) => {
        // console.log({ item })
        for (const key in item.meta.colors) {
          this.createCSSClass(
            `table-lizard-assetboard-class-${item.uid}-${key}`,
            `
            background-color: ${item.meta.colors[key] || '#c4c4c4'};
          `
          )
        }
        item._cellVariants = {
          subsector: `lizard-assetboard-class-${item.uid}-subsector`,
          status: `lizard-assetboard-class-${item.uid}-status`,
          deck: `lizard-assetboard-class-${item.uid}-deck`,
          priority: `lizard-assetboard-class-${item.uid}-priority`
        }
        // item._rowVariant = 'lizard-assetboard-rowVar'
        return item
      })
      let result = new Array(total).fill({})
      for (let i = 0; i < list.length; i++) {
        result[(this.Pager.page - 1) * this.Pager.perPage + i] = list[i]
      }
      return result
    },
    getLabelFromMeta(group, item) {
      return (
        this.metas[group]?.find(
          (sub) => sub.value.toLowerCase() === item.toLowerCase()
        )?.text ?? null
      )
    },
    SetEbitdaFilter() {
      if (this.loadingAssetBoard) return
      let filters = {}
      filters['ebitda'] = this.filters.ebitda.range
      filters = Object.assign({}, this.Filters, filters)
      this.commitToStore('setAssetBoardFilters', filters)
      this.commitToStore('setAssetBoardPager', { page: 1 })
    },
    selectFilterTag(key, value = this.filters[key].temp[0]) {
      this.loadingAssetBoard = true
      const multipleSelectFields = [
        'asset',
        'subsector',
        'ebitda',
        'ibank',
        'hqcountry'
      ]
      if (multipleSelectFields.includes(key)) {
        if (!this.filters[key].list.includes(value) && value) {
          this.filters[key].list.push(value)
          let filters = {}
          filters[key] = this.filters[key].list
          filters = Object.assign({}, this.Filters, filters)
          this.commitToStore('setAssetBoardFilters', filters)
          this.commitToStore('setAssetBoardPager', { page: 1 })
        }
      } else {
        let filters = {}
        filters[key] = [this.filters[key].val]
        filters = Object.assign({}, this.Filters, filters)
        this.commitToStore('setAssetBoardFilters', filters)
        this.commitToStore('setAssetBoardPager', { page: 1 })
      }
      this.filters[key].temp = []
      this.loadingAssetBoard = false
    },
    removeFilterTag(key, value) {
      this.loadingAssetBoard = true
      this.filters[key].val = ''
      this.filters[key].temp = []
      this.filters[key].list = this.filters[key].list.filter(
        (item) => item !== value
      )
      let toRemove = {}
      toRemove[key] = this.filters[key].list
      toRemove = Object.assign({}, this.Filters, toRemove)
      this.commitToStore('resetAssetBoardFilters', toRemove)
      this.commitToStore('setAssetBoardPager', { page: 1 })
      this.loadingAssetBoard = false
    },
    GetSliderTooltipValue(val) {
      return Number(val) === 50 ? '50+' : val
    },
    clearFilters() {
      for (const key in this.filters) {
        if (key === 'ebitda') {
          this.filters[key].temp = []
          this.filters[key].range = [0, 50]
        } else {
          this.filters[key].val = ''
          this.filters[key].temp = []
          this.filters[key].list = []
        }
      }
      this.commitToStore('setAssetBoardPager', { page: 1 })
      this.commitToStore('resetAssetBoardFilters', {}, true)
    }
  },
  watch: {
    'subsectorTreeSettings.val': {
      handler(val) {
        val = val.map((item) => {
          const categoryIndex = this.subsectorTreeSettings.options.findIndex(
            (opt) => opt.id === item
          )
          const isMainCategory = categoryIndex > -1
          if (isMainCategory) {
            return this.subsectorTreeSettings.options[
              categoryIndex
            ].children.map((sub) => sub.id)
          } else {
            return item
          }
        })
        const toRemove = this.filters.subsector.list.filter(
          (itm) => !val.includes(itm)
        )
        if (toRemove.length) {
          toRemove.forEach((item) => {
            this.removeFilterTag('subsector', item)
          })
        }
        val.forEach((item) => {
          this.selectFilterTag('subsector', item)
        })
      }
    },
    Pager: {
      handler() {
        this.fetchData({ monday: false })
      },
      deep: true
    }
  }
}
</script>

<style scoped>
@import '@riophae/vue-treeselect/dist/vue-treeselect.min.css';

.lizard-slider-tooltip {
  color: #999;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
}
.lizard-col-padding {
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.lizard-monday-btn {
  margin: 0 2px;
  padding: 0;
  width: 46%;
  height: 30px;
  line-height: 30px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  color: #999;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;
}
.lizard-monday-btn:hover {
  border-color: #999;
}
.lizard-monday-btn:active {
  border-color: #666;
}
.lizard-monday-btn img {
  height: 30px !important;
}
.lizard-clear-filters-btn {
  margin: 0 2px;
  padding: 0;
  width: 46%;
  height: 30px;
  line-height: 30px;
  background-color: #999;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;
}
.lizard-clear-filters-btn:hover {
  border-color: #999;
}
.lizard-clear-filters-btn:active {
  border-color: #666;
}
</style>

<style>
*:focus {
  border: transparent !important;
  outline: none !important;
}
ul.filterTags {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul.filterTags li {
  margin: 0 2px;
  display: inline-block;
}
.lizard-update-input {
  font-size: 0.8rem !important;
  font-weight: bold !important;
  border: none !important;
  background-color: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
  max-width: 150px !important;
  text-align: center !important;
}
.lizard-update-select {
  font-size: 0.8rem !important;
  font-weight: bold !important;
  max-width: 150px !important;
}
.lizard-text-cell-2 {
  width: calc(100vw / 7) !important;
  min-width: calc(100vw / 7) !important;
  max-width: calc(100vw / 7) !important;
  margin: 0 !important;
  padding: 5px !important;
  overflow: auto;
}
.lizard-status-cell-finding-bidder {
  background-color: #e8697d;
}
.lizard-status-cell-hired {
  background-color: #55d392;
}
.lizard-status-cell-talk-to-owner {
  background-color: #fdbc64;
}
.lizard-status-cell-deck-done {
  background-color: #349ecd;
}
.lizard-status-cell-do-deck {
  background-color: #b57de3;
}
.lizard-status-cell-default {
  background-color: #797e93;
}
.lizard-status-cell {
  color: white;
  min-width: 130px !important;
}
.lizard-status-cell div:hover {
  cursor: pointer;
}
.lizard-deck-cell-no-need {
  background-color: #349ecd;
}
.lizard-deck-cell-medium {
  background-color: #fdbc64;
}
.lizard-deck-cell-high {
  background-color: #e8697d;
}
.lizard-deck-cell-done {
  background-color: #55d392;
}
.lizard-deck-cell-default {
  background-color: #797e93;
}
.lizard-deck-cell {
  color: white;
  min-width: 130px !important;
}
.lizard-deck-cell div:hover {
  cursor: pointer;
}
.lizard-setting-badge {
  margin: 0;
  min-width: 60px !important;
}
.lizard-setting-badge:hover {
  cursor: pointer;
  color: #eee !important;
  opacity: 0.6 !important;
}
.lizard-setting-badge:active {
  color: #ccc !important;
  opacity: 0.8 !important;
}
.lizard-setting-badge-temp {
  opacity: 0.4 !important;
}
.lizard-monday-button {
  max-width: 10vw !important;
  margin: 0;
  padding: 8px 6px;
  cursor: pointer;
}
.lizard-monday-button:active {
  max-width: 90% !important;
}
.lizard-monday-sync {
  opacity: 0.5 !important;
  border: #666;
  cursor: default;
}
.lizard-monday-sync img {
  opacity: 0.5 !important;
}
.lizard-ebitda-groups {
  width: 100% !important;
  line-height: 1 !important;
}
.lizard-ebitda-groups label {
  background-color: #eee !important;
  color: #ccc !important;
  height: 32px !important;
  min-height: 32px !important;
  line-height: 2 !important;
  vertical-align: middle !important;
  box-sizing: border-box;
  min-width: 25% !important;
  font-size: 75% !important;
  padding: auto 0 !important;
  border: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lizard-ebitda-groups label:hover {
  background-color: #ccc !important;
  color: #eee !important;
}
.lizard-ebitda-groups label.focus {
  box-shadow: 0 0 0 transparent !important;
}
.lizard-ebitda-groups label.active {
  /* background-color: #17a2b8 !important; */
  background-color: #66ccff !important;
  color: #fff !important;
}

.vue-treeselect__control {
  height: 31px !important;
  border-color: #ced4da !important;
  border-radius: 0.25rem !important;
}

.vue-treeselect__placeholder,
.vue-treeselect__single-value {
  line-height: 29px !important;
}

.vue-treeselect__placeholder {
  color: #495057 !important;
  font-weight: 400 !important;
  font-size: 0.875rem !important;
}
</style>
