<template>
  <b-container fluid>
    <div class="row">
      <div class="col-lg-12 text-center" id="HolidayFilters">
        <b-card bg-variant="light">
          <div class="row">
            <div class="col-lg-3 text-right">
              <b-form-radio-group
                class="allowance-year-group"
                v-model="selectedDateLabel"
                :options="dateLabels"
                button-variant="outline-secondary"
                name="allowance-years"
                buttons
              ></b-form-radio-group>
            </div>
            <div class="col-lg-4 text-center">
              <el-date-picker
                id="datesFilterInput"
                type="daterange"
                range-separator=""
                start-placeholder="From"
                end-placeholder="To"
                class="form-control lizard-form-datepicker"
                size="small"
                value-format="yyyy-MM-dd"
                format="dd/MM/yyyy"
                v-model="datesFilter"
                :picker-options="datePickerOptions"
              ></el-date-picker>
            </div>
            <div class="col-lg-3 text-left">
              <b-form-group
                id="filter-group-1"
                label=""
                label-for="teamMembersFilterInput"
                description=""
              >
                <b-form-input
                  id="teamMembersFilterInput"
                  list="teamMembers"
                  size="sm"
                  placeholder="Search for a user..."
                  v-model="reportsFilters.user.fullName"
                  @keyup="checkUsersList"
                  @keyup.enter="selectUser"
                  @blur="selectUser"
                ></b-form-input>
                <ul class="filterTags">
                  <li v-for="user in teamTempSearch" :key="user.uid">
                    <b-badge
                      variant="secondary"
                      class="lizard-setting-badge lizard-setting-badge-temp"
                      title="Click to select this user"
                      @click="userFilter = user.fullName"
                      v-b-tooltip.hover.bottom="{ variant: 'info' }"
                      >{{ user.fullName }}</b-badge
                    >
                  </li>
                </ul>
              </b-form-group>
            </div>
            <div class="col-lg-2 text-right"><create-holiday /></div>
          </div>
        </b-card>
      </div>
    </div>
  </b-container>
</template>

<script>
import Services from '../../../services/main.service.vue'
import CreateHoliday from './Create.vue'
import { format as dateFormat, isWeekend } from 'date-fns'
export default {
  name: 'FitersBar',
  components: {
    CreateHoliday
  },
  data() {
    return {
      bankHolidays: [],
      teamMembers: [],
      teamTempSearch: [],
      selectedYear: null,
      selectedMonth: [],
      years: [],
      months: []
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    reportsFilters() {
      return this.Store.leavesFilters
    },
    dateLabels() {
      return this.reportsFilters.labels
    },
    selectedDateLabel: {
      get() {
        return this.reportsFilters.dateLabel
      },
      set(label) {
        this.commitToStore('setLeavesFilters', {
          filter: 'dateLabel',
          data: label
        })
      }
    },
    datesFilter: {
      get() {
        return this.reportsFilters.dates
      },
      set(dates) {
        this.commitToStore('setLeavesFilters', {
          filter: 'dates',
          data: dates
        })
      }
    },
    userFilter: {
      get() {
        return this.reportsFilters.user.fullName
      },
      set(fullName) {
        fullName = fullName.trim()
        const user = this.teamMembers.find(
          (member) => member.fullName === fullName
        )
        this.commitToStore('setLeavesFilters', {
          filter: 'user',
          data: {
            uid: user?.uid ?? null,
            fullName
          }
        })
        this.teamTempSearch = []
      }
    },
    datePickerOptions() {
      return {
        firstDayOfWeek: 1,
        disabledDate: (dt) => {
          const isBankHoliday = this.bankHolidays.includes(
            dateFormat(new Date(dt), 'yyyy-MM-dd')
          )
          return isWeekend(new Date(dt)) || isBankHoliday
        }
      }
    }
  },
  methods: {
    async SetUsersList() {
      const Users = await Services.Users.Admin.getList()
      const List =
        Users?.data?.content
          ?.filter((user) => {
            return this.onlyActiveUsers ? user.settings.active : true
          })
          ?.map(({ uid, firstName, lastName, role, email }) => {
            return {
              uid,
              fullName: `${firstName} ${lastName}`.trim(),
              role,
              email
            }
          }) ?? []
      this.teamMembers = List.sort((a, b) => {
        if (a.fullName < b.fullName) {
          return -1
        }
        if (a.fullName > b.fullName) {
          return 1
        }
        return 0
      })
    },
    async checkUsersList(ev) {
      if (!this.teamMembers.length) {
        await this.SetUsersList()
      }
      this.teamTempSearch = []
      const value = ev?.target?.value?.trim() ?? null
      if (value && value.length > 1) {
        const searchReg = new RegExp(`^${value}`, 'ig')
        const results = this.teamMembers.find((member) => {
          return searchReg.test(member.fullName) ? member : null
        })
        this.teamTempSearch = results ? [results] : []
      }
    },
    selectUser() {
      if (this.teamTempSearch.length) {
        this.userFilter = this.teamTempSearch[0].fullName
      } else {
        this.userFilter = ''
      }
    },
    commitToStore(commitName, value) {
      this.$store.commit(commitName, value)
    }
  }
}
</script>

<style scoped>
.filters-list {
  list-style: none;
  width: 100% !important;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.filters-list li {
  margin: 0 !important;
  padding: 0 !important;
}
.filters-list li div#filter-group-1 {
  margin: 0 !important;
}
</style>

<style>
#HolidayFilters {
  display: block;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}
#HolidayFilters .allowance-year-group {
  min-width: fit-content !important;
  line-height: 1 !important;
  margin: 0 !important;
}
#HolidayFilters .allowance-year-group label {
  background-color: #eee !important;
  color: #ccc !important;
  height: 32px !important;
  min-height: 32px !important;
  line-height: 2 !important;
  vertical-align: middle !important;
  box-sizing: border-box;
  font-size: 75% !important;
  padding: auto 0 !important;
  border: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
#HolidayFilters .allowance-year-group label:hover {
  background-color: #ccc !important;
  color: #eee !important;
}
#HolidayFilters .allowance-year-group label.focus {
  box-shadow: 0 0 0 transparent !important;
}
#HolidayFilters .allowance-year-group label.active {
  /* background-color: #17a2b8 !important; */
  background-color: #66ccff !important;
  color: #fff !important;
}
</style>
