<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="dark" v-if="showAd">
      <b-navbar-brand>
        <img src="../assets/notif-img.jpg" class="notif-img" />
      </b-navbar-brand>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'AdBanner',
  data() {
    return {
      showAd: this?.$store?.getters?.showAd ?? false
    }
  }
}
</script>

<style scoped>
img {
  cursor: pointer;
}
pre {
  display: inline-flex;
  margin: 0 0 0 10px;
}
.env-badge {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  padding: 4px 6px 2px 10px;
  letter-spacing: 0.2em;
  cursor: pointer;
}
.lizard-navbar-azure {
  position: absolute;
  right: 2% !important;
}
.bg-dark {
  background-color: black !important;
  text-align: center !important;
}
.notif-img {
  max-width: 100% !important;
}
</style>
